import {
  FiscaleVoortzettingOptions,
  RentevariantOptions,
  AflossingsVormType,
  PeriodiekeAflossingInput,
  PeriodiekeAflossingOutput
} from "../../.generated/forms/formstypes";
import {
  MarktwaardesOutput,
  RentevariantMetRenteperiodesRenteafspraak
} from "../../.generated/hypotheekrentes/hypotheekrentestypes";
import { InlegOutput, VermogensrekeningInput } from "../../.generated/vermogen/vermogentypes";
import { partijOnafhankelijk } from "../../producten-overzicht/infra/product-constanten";
import { mapKenmerken } from "../../producten-overzicht/infra/product-kenmerken-mapping";
import {
  HypothekenKenmerken,
  KenmerkenError,
  ProductKenmerkenDlType
} from "../../producten-overzicht/infra/product-kenmerken-types";
import {
  createISWAsyncSideEffect,
  initISWAsyncSideEffect
} from "../../shared/components/isw-side-effects/create-isw-helpers";
import { jaarMaandInMaanden } from "../../shared/generic-parts/jaar-maand/map-ui-2-dl";
import { partijcodeING } from "../../vermogen/infra/vermogen-types";
import { HypothekenState } from "./hypotheek-types";
import { mapDlTargetToHypotheekUiFieldPeriodiekeAflossing } from "./map-hypotheek-dl-2-ui";
import { getKenmerkCodeForProduct } from "./hypotheek-utils";
import { defaultProductkenmerkenStarterslening } from "./default-productkenmerken-starterslening";
import { isEqual } from "lodash";
import {
  AsyncContext,
  hypotheekAsyncOpties,
  getRentevariantenMetRentePeriodes,
  getHypotheekrentes,
  bepaalRenteperiodeMetRentepercentage,
  bepaalWaarschuwing,
  bepaalRentepercentageIng,
  bepaalRentepercentage,
  getRenteboxCode,
  getSoortOnderpand,
  mapPeriodiekeAflossingInput,
  cleanFiscaleRegeling,
  cleanKapitaalopbouw,
  cleanPremiegegevens,
  mapPremieBerekeningInput,
  berekenWaardeopbouwBedrag,
  berekenWaardeopbouwMaanden,
  indexedFetchCall
} from "./determine-hypotheek-async-helpers";

export const hypotheekRenteBepalen = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const product = draft.producten[context.selected];

    if (
      !product.hypotheekVorm.isStartersLening ||
      !product.hypotheekVorm.isRestschuldLening ||
      !product.product.doorlopend
    ) {
      const optiesResult = await hypotheekAsyncOpties(draft, context);

      // alleen opties overschrijven als we nieuwe opties hebben, anders levert dit conflicteren patchset op
      if (
        optiesResult !== null &&
        !isEqual(optiesResult?.hypotheekOpties, draft.hypotheekOptie.hypotheekOpties) &&
        optiesResult?.hypotheekOpties.length > 0
      ) {
        draft.hypotheekOptie.hypotheekOpties = optiesResult?.hypotheekOpties;
      }
    }

    const marktwaardePercentage = draft.panden.find(p => p)?.bevoorschottingspercentage ?? 100;

    // Now proceed with rente calculations
    const [rentevariantenTotaal, hypotheekrentes] = await Promise.all([
      getRentevariantenMetRentePeriodes(
        settings.hypotheekrentesOrigin,
        draft.producten,
        marktwaardePercentage,
        !!draft.nhg,
        context.hypotheekvormen,
        fetchData
      ),
      getHypotheekrentes(
        settings.klantdossiersFormsOrigin,
        draft.producten,
        draft.hypotheekOptie.hypotheekOpties,
        context.voorstelId,
        context.hypotheekvormen,
        fetchData
      )
    ]);

    // Update rentevarianten
    draft.producten.forEach((product, index) => {
      if (
        product.product.doorlopend ||
        product.hypotheekVorm.isRestschuldLening ||
        product.hypotheekVorm.isStartersLening
      ) {
        return;
      }

      const rentevarianten = rentevariantenTotaal[index];
      if (rentevarianten?.length > 0) {
        bepaalRenteperiodeMetRentepercentage(product, rentevarianten);
      }
      bepaalWaarschuwing(product, rentevarianten, draft.nhg);

      const renteafspraak =
        product.leningdeelgegevens.renteVariant === RentevariantOptions.Variabel
          ? RentevariantMetRenteperiodesRenteafspraak._3
          : product.leningdeelgegevens.renteVariant === RentevariantOptions.Rentevast
          ? RentevariantMetRenteperiodesRenteafspraak._1
          : null;

      const renteboxSoort = rentevarianten?.find(x => x.renteafspraak === renteafspraak)?.code ?? null;
      if (renteboxSoort) {
        product.renteBoxSoort = renteboxSoort;
      }
    });

    // Update rentepercentages
    for (let i = 0; i < draft.producten.length; i++) {
      const product = draft.producten[i];
      if (!product.leningdeelgegevens.rentePercentage?.berekenen) {
        continue;
      }

      if (draft.producten.some(p => p.partijCode === partijcodeING)) {
        await bepaalRentepercentageIng(settings.klantdossiersFormsOrigin, draft, context, fetchData);
      } else {
        bepaalRentepercentage(product, hypotheekrentes[i]);
      }
    }
  }
);

export const hypotheekAsyncAutomatischeRentedaling = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const selectedProduct = draft.producten[context.selected];
    const geldverstrekkerCode = selectedProduct.partijCode;
    const hypotheeklabelCode = selectedProduct.labelCode;
    const renteboxcode = getRenteboxCode(context.hypotheekvormen, selectedProduct);
    if (!renteboxcode) return;
    const result = await fetchData<MarktwaardesOutput>({
      method: "GET",
      url:
        `${settings.hypotheekrentesOrigin}/Geldverstrekkers/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxcode}/Marktwaardes/Opslagen` +
        `?renteafspraak=${
          draft.producten[context.selected].leningdeelgegevens.renteVariant === RentevariantOptions.Rentevast ? 1 : 3
        }` +
        `&rentevastAantalMaanden=` +
        (Number(draft.producten[context.selected].leningdeelgegevens.rentevastPeriodeJaar) * 12 ?? 0) +
        `&rentebedenktijdInMaanden=` +
        (Number(draft.producten[context.selected].leningdeelgegevens.renteBedenktijdJaar) * 12 ?? 0) +
        `&soortOnderpand=${getSoortOnderpand(draft.producten[context.selected])}` +
        `&marktwaardePercentage=${draft.panden.find(p => p)?.bevoorschottingspercentage ?? 100}` +
        `&nhg=${!!draft.nhg}`
    });

    /* istanbul ignore else */
    if (result.isValid) {
      draft.producten[context.selected].leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = [];

      result.marktwaardes
        ?.filter(c => !c.nhg)
        .forEach(c =>
          draft.producten[
            context.selected
          ].leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages?.push({
            marktwaardePercentageTotEnMet:
              c.marktwaardePercentageTotEnMet === 0 && c.marktwaardePercentageVanaf
                ? c.marktwaardePercentageVanaf
                : c.marktwaardePercentageTotEnMet
                ? c.marktwaardePercentageTotEnMet
                : 0,
            renteopslagPercentage: c.renteopslagPercentage ?? 0
          })
        );
    }
  }
);

// In voorstel, bij niet-NHG. wanneer op het leningdeel 'automatischeRentedalingModal.rentedalingPercentages' nog niet is gezet
// proberen we de marktwaardes uit de fetch verkregen te gebruiken.
export const automatischeRentedalingOpslagenBepalen = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const selectedProduct = draft.producten[context.selected];
    const geldverstrekkerCode = selectedProduct.partijCode;
    const hypotheeklabelCode = selectedProduct.labelCode;
    const renteboxcode = getRenteboxCode(context.hypotheekvormen, selectedProduct);
    const renteVariant = selectedProduct.leningdeelgegevens.renteVariant as RentevariantOptions;
    const rentevastJaren = Number(selectedProduct.leningdeelgegevens.rentevastPeriodeJaar);
    const rentebedenktijdInJaren = selectedProduct.leningdeelgegevens.renteBedenktijdJaar;
    const marktwaardePercentage = draft.panden.find(p => p)?.bevoorschottingspercentage ?? 100;

    const soortOnderpand = getSoortOnderpand(selectedProduct);

    if (!renteboxcode) return;

    // bij uitschakelen automatische rentedaling, opslagen opschonen
    if (
      !selectedProduct.leningdeelgegevens.automatischeRentedaling &&
      selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages.length
    ) {
      selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = [];
      return;
    }

    /* istanbul ignore else*/
    if (
      !selectedProduct.leningdeelgegevens.automatischeRentedaling ||
      selectedProduct.partijCode === partijOnafhankelijk
    )
      return;

    const requestUrl =
      `${settings.hypotheekrentesOrigin}/Geldverstrekkers/${geldverstrekkerCode}/Hypotheeklabels/${hypotheeklabelCode}/Hypotheekvormen/${renteboxcode}/Marktwaardes/Opslagen` +
      `?renteafspraak=${renteVariant === RentevariantOptions.Rentevast ? 1 : 3}` +
      `&rentevastAantalMaanden=` +
      (Number(rentevastJaren) * 12 ?? /* istanbul ignore next */ 0) +
      `&rentebedenktijdInMaanden=` +
      (Number(rentebedenktijdInJaren) * 12 ?? /* istanbul ignore next */ 0) +
      `&soortOnderpand=${soortOnderpand}` +
      `&marktwaardePercentage=${marktwaardePercentage ?? /* istanbul ignore next */ 100}` +
      `&nhg=${!!draft.nhg}`;

    const { marktwaardes } = await fetchData<MarktwaardesOutput>({
      url: requestUrl,
      method: "GET"
    });

    const mwPercentages =
      marktwaardes?.map(c => {
        return {
          marktwaardePercentageTotEnMet:
            c.marktwaardePercentageTotEnMet === 0 && c.marktwaardePercentageVanaf
              ? c.marktwaardePercentageVanaf
              : c.marktwaardePercentageTotEnMet
              ? c.marktwaardePercentageTotEnMet
              : 0,
          renteopslagPercentage: c.renteopslagPercentage ?? /* istanbul ignore next */ 0
        };
      }) || [];

    selectedProduct.leningdeelgegevens.automatischeRentedalingModal.rentedalingPercentages = mwPercentages;
  }
);

export const hypotheekAsyncPeriodiekeAflossing = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const result = await fetchData<PeriodiekeAflossingOutput, PeriodiekeAflossingInput>({
      url: `${settings.klantdossiersFormsOrigin}/Hypotheek/Periodieke Aflossing`,
      body: mapPeriodiekeAflossingInput(context.selected),
      mapperDlNameToUiName: mapDlTargetToHypotheekUiFieldPeriodiekeAflossing(context.selected)
    });

    /* istanbul ignore else */
    if (result.isValid) {
      draft.producten[context.selected].leningdeelgegevens.periodiekeAflossing =
        result.resultaat?.aflossingBedrag ?? /* istanbul ignore next */ null;
    }
  }
);

export const cleanProductObvProductkenmerken = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, context }) => {
    const productkenmerken = context.kenmerken;
    const product = draft.producten[context.selected];
    if (productkenmerken) {
      cleanFiscaleRegeling(productkenmerken, product);
      cleanKapitaalopbouw(productkenmerken, product);
      cleanPremiegegevens(productkenmerken, product);
    }
  }
);

export const hypotheekAsyncBerekenPremie = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const requestUrl = `${settings.vermogenOrigin}/Vermogensrekening/Inleg`;
    const result = await fetchData<InlegOutput, VermogensrekeningInput>({
      url: requestUrl,
      body: mapPremieBerekeningInput(context.selected)
    });

    /* istanbul ignore next */
    if (result.isValid) {
      const premie = draft.producten[context.selected].premieGegevens;

      /* istanbul ignore next */
      if (premie) {
        if (premie.hoogLaagLooptijd && (premie.hoogLaagLooptijd.jaren || premie.hoogLaagLooptijd.maanden)) {
          premie.totalePremieLaag = result.resultaat?.inleg ?? null;
          premie.totalePremieHoog = result.resultaat?.inleg ?? null; // todo wachten op de juist berekening
          premie.spaarPremieLaag = null;
        } else {
          premie.spaarPremieLaag = result.resultaat?.inleg ?? null; // todo wachten op de juist berekening
          premie.totalePremieLaag = null;
          premie.totalePremieHoog = null;
        }
      }

      // De eerste maand krijgen we nog geen rendement uit /Inleg terug om die reden 'knippen' we maand 1 eraf, en hernummeren we de maanden -1.
      const waardeopbouw = result.resultaat?.waardeopbouwPerMaand
        ?.slice(1, result.resultaat?.waardeopbouwPerMaand.length)
        .map(opbouw => {
          return { ...opbouw, maand: opbouw.maand == null ? null : opbouw.maand - 1 };
        });

      const productwaarde = draft.producten[context.selected].productwaarde;
      if (productwaarde && waardeopbouw) {
        productwaarde.waardeopbouwBedrag = berekenWaardeopbouwBedrag(waardeopbouw);
        productwaarde.waardeopbouwMaanden = berekenWaardeopbouwMaanden(waardeopbouw);
      }
    }
  }
);

export const productKenmerkenOphalen = createISWAsyncSideEffect<HypothekenState, AsyncContext>(
  async ({ draft, settings, fetchData, context }) => {
    const [productKenmerken, productKenmerkenMapActions] = context.kenmerkenMapActions;
    const kenmerkenCalls: indexedFetchCall<ProductKenmerkenDlType>[] = [];
    // voor ieder product waarvoor geen kenmerken aanwezig zijn, fetch call klaarzetten.
    draft.producten.forEach((prod, index) => {
      // Als het een starterslening is dan default productkenmerken gebruiken anders ophalen
      if (prod.hypotheekVorm.isStartersLening) {
        const kenmerken = defaultProductkenmerkenStarterslening;
        productKenmerkenMapActions.set(`${prod.partijCode}-${prod.productCode.padStart(2, "0")}`, kenmerken);
      } else {
        if (productKenmerken.get(getKenmerkCodeForProduct(prod)) !== undefined) return;
        const url = `${settings.productenOrigin}/MaatschappijCodes/${
          prod.partijCode
        }/Hypotheekvormen/${prod.productCode.padStart(2, "0")}/Productkenmerken`;

        kenmerkenCalls.push({
          call: fetchData({ url, method: "GET" }),
          index
        });
      }
    });

    // Alle fetch calls uitvoeren
    const fetchedKenmerken = await Promise.all(kenmerkenCalls.map(kc => kc.call));
    fetchedKenmerken.forEach((fk, index) => {
      const currProduct = draft.producten[kenmerkenCalls[index].index];
      const kenmerken = mapKenmerken("Hypotheek", fk) as HypothekenKenmerken;

      if (!fk.isValid) {
        productKenmerkenMapActions.set(getKenmerkCodeForProduct(currProduct), {
          reden: "product niet beschikbaar"
        } as KenmerkenError);
        return;
      }

      // Map gedefinieerd in hypotheek-ajax.tsx updaten met toevoeging(en).
      kenmerken &&
        productKenmerkenMapActions.set(
          `${currProduct.partijCode}-${currProduct.productCode.padStart(2, "0")}`,
          kenmerken
        );
    });
  }
);

export const hypotheekAsyncSideEffects = initISWAsyncSideEffect<HypothekenState, AsyncContext>(
  ({ has, curr, prev, runAsync, context }) => {
    const product = curr.producten[context.selected];
    const hasProduct = has.producten[context.selected];
    const [productKenmerken, productKenmerkenMapActions] = context.kenmerkenMapActions;
    const [bereken, setBereken] = context.berekenState;
    if (has.producten.changed || (curr.producten.length && context.kenmerkenMapActions[0].keys.length === 0)) {
      runAsync(productKenmerkenOphalen(context));
    }

    // Opschonen van kenmerken map als er geen producten meer zijn
    if (has.producten.changed && productKenmerken.size && !curr.producten.length) {
      productKenmerkenMapActions.reset();
    }

    if (has.producten.changed && prev.producten.length < curr.producten.length && curr.producten.length > 0) {
      let idx = -1;
      curr.producten.forEach(prd => {
        idx++;
        const hasPrd = has.producten[idx];
        if (hasPrd.partijCode.changed || hasPrd.productCode.changed) {
          const kenmerken =
            (productKenmerken.get(getKenmerkCodeForProduct(curr.producten[idx])) as HypothekenKenmerken) ?? null;
          runAsync(cleanProductObvProductkenmerken({ ...context, selected: idx, kenmerken: kenmerken }));
        }
      });
    }

    if (context.situatie === "huidig" && product) {
      const fieldChanged =
        hasProduct.hypotheekVorm.aflossingsvorm.changed ||
        hasProduct.leningdeelgegevens.leningdeelHoofdsom.bedrag.changed ||
        hasProduct.product.ingangsdatum.changed ||
        hasProduct.product.looptijd.changed ||
        hasProduct.leningdeelgegevens.datumOpgave.changed ||
        hasProduct.leningdeelgegevens.rentePercentage.changed ||
        hasProduct.leningdeelgegevens.periodiekeAflossing.changed ||
        hasProduct.isNieuw.changed;

      if (
        fieldChanged &&
        product.leningdeelgegevens.leningdeelHoofdsom.bedrag !== null &&
        product.product.ingangsdatum !== null &&
        jaarMaandInMaanden(product.product.looptijd) !== null
      ) {
        runAsync(hypotheekAsyncPeriodiekeAflossing(context));
      }
    }

    if (context.situatie === "voorstel" && product) {
      const fieldChangedPremie =
        hasProduct.verzekerde.verzekerde.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal1Bedrag.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal2Bedrag.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal1Percentage.changed ||
        hasProduct.kapitaalopbouw.doelkapitaal2Percentage.changed ||
        hasProduct.premieGegevens.looptijd.changed ||
        hasProduct.premieGegevens.aanvangExtraPremieStortingenBedrag.changed ||
        hasProduct.premieGegevens.hoogLaagLooptijd.changed ||
        hasProduct.premieGegevens.betalingstermijn.changed ||
        hasProduct.fiscaleRegeling.fiscaleVoortzetting.changed ||
        hasProduct.leningdeelgegevens.rentevastPeriodeJaar.changed ||
        hasProduct.leningdeelgegevens.automatischeRentedaling.changed;

      if (
        bereken ||
        (fieldChangedPremie &&
          product.hypotheekVorm.aflossingsvorm === AflossingsVormType.Spaarrekening &&
          product.fiscaleRegeling?.fiscaleVoortzetting !== FiscaleVoortzettingOptions.VoortgezetProduct &&
          product.partijCode !== partijOnafhankelijk)
      ) {
        runAsync(hypotheekAsyncBerekenPremie(context));
        setBereken(false);
      }

      if (
        fieldChangedPremie &&
        product.leningdeelgegevens.automatischeRentedaling &&
        product.partijCode !== partijOnafhankelijk
      ) {
        runAsync(hypotheekAsyncAutomatischeRentedaling(context));
      }

      if (
        !curr.nhg &&
        has.producten.changed &&
        hasProduct.leningdeelgegevens.automatischeRentedaling.changed &&
        !product.product.doorlopend
      ) {
        runAsync(automatischeRentedalingOpslagenBepalen(context));
      }

      if (context.situatie === "voorstel" && (has.producten.changed || has.hypotheekOptie.changed)) {
        runAsync(hypotheekRenteBepalen(context));
      }
    }
  }
);
