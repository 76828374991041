/* istanbul ignore file */
/* eslint-disable */
import React from "react";
import { Table } from "react-bootstrap";
import { LabeledRadioInput } from "adviesbox-shared";
import "./scenario-table.scss";
import { NbiResult, InkomensanalyseResultaat, Inkomensanalyse, ToelichtingRegel } from "../../../.generated/rapportage/rapportagetypes";
import { TableNettoBesteedbaarInkomen } from "./nbi";
import { ScenarioBaseProps } from "./scenario-drawer";
import { Formik } from "formik";
import { Direction } from "../../../shared/types";
import { bedragFormatNull, monthDivision } from "./infra/scenario-utils";
import { ControleMaandlastOptions } from "../../../.generated/forms/formstypes";
import { CardLoadingSpinner } from "./card-loading-spinner";
import { getNaam } from "../../../shared/utils/helpers";
import { ControleMaandlastTable } from "./tables/controle-maandlast-table";
import { BenodigdInkomenTable } from "./tables/benodigd-inkomen-table";
import { BenodigdKapitaalTable } from "./tables/benodigd-kapitaal-table";
import { MaximaleHypotheekTable } from "./tables/maximale-hypotheek";
import { ControleWerkelijkeLastenTable } from "./tables/controle-werkelijke-lasten-table";
import { BrutoInkomensWensenTable } from "./tables/bruto-inkomens-wensen";
import { CitationProvider, excludedFootnotes, CitationNote } from "./citation-notes";

export type OverlijdenProps = {
  nbiWeergeven: boolean;
  inkomensanalyseResultaten: InkomensanalyseResultaat[];
  motiveringInkomensanalyse: Inkomensanalyse | null;
  totaalHuidigBrutoJaarinkomenBedrag: number | null;
  controleInkomenWeergeven: boolean;
  titel: string;
  conclusie: string;
  nbi: NbiResult[];
  inkomensData?: {
    anwUitkeringBedrag: number | null;
    anwHiaatVerzekeringBedrag: number | null;
    anwHiaatVerzekeringBruteringBedrag: number | null;
    nabestaandenpensioen: number | null;
    wezenpensioen: number | null;
    inkomensaanvullingBedrag: number | null;
    inkomensaanvullingBruteringBedrag: number | null;
    inkomenPartner: number | null;
    totaalInkomen: number | null;
    inkomensverlies: number | null;
  };
  jaarlijkseAnalyse?: {
    gewenstInkomen: number | null;
    gewenstInkomenPercentage: number | null;
    maandelijksTekort: number | null;
  };
  financieleGegevens?: {
    benodigdInkomenHypotheek: number | null;
    tekortOpInkomen: number | null;
    maximaleHypotheek: number | null;
    hypotheekSaldo: number | null;
    overschrijding: number | null;
    toegestaneLast: number | null;
    werkelijkeBrutoLast: number | null;
    tekortWerkelijkeBrutolast: number | null;
  };
  voetnoten: ToelichtingRegel[];
  benodigdKapitaal: number | null;
  benodigdKapitaalOmschrijving: string;
};

const InkomensanalyseTabelOverlijden: React.FC<OverlijdenProps & ScenarioBaseProps> = ({
  titel,
  conclusie,
  inkomensData,
  voetnoten,
  nbi,
  nbiWeergeven,
  benodigdKapitaal,
  benodigdKapitaalOmschrijving,
  weergevenOptions,
  selectedKlantId,
  onKlantIdChange,
  maximaleHypotheekWeergeven,
  benodigdKapitaalWeergeven,
  controleWerkelijkeLastenWeergeven,
  brutoInkomensWensenWeergeven,
  brutoInkomensWensPercentage,
  jaarlijkseAnalyse,
  controleMaandlast,
  controleMaandlastKeuze,
  loading,
  inkomensanalyseResultaten,
  motiveringInkomensanalyse
}) => {

  const aanvrager = motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId ? motiveringInkomensanalyse.aanvrager1 : motiveringInkomensanalyse?.aanvrager2;
  const partner = motiveringInkomensanalyse?.aanvrager1?.klantId === selectedKlantId ? motiveringInkomensanalyse.aanvrager2 : motiveringInkomensanalyse?.aanvrager1;
  const inkomensaanvulling = (inkomensData?.inkomensaanvullingBedrag || 0) + (inkomensData?.inkomensaanvullingBruteringBedrag || 0);

  return (
    <CitationProvider voetnoten={voetnoten} excludedFootnotes={excludedFootnotes}>
      <div className="container-fluid mt-2 px-2">
        <h2 className="mb-3">{titel}</h2>
        {loading && <CardLoadingSpinner />}
        {weergevenOptions.length > 0 && (
          <Formik initialValues={{ scenarioKlantId: selectedKlantId }} enableReinitialize onSubmit={() => { }}>
            {({ setFieldValue }) => (
              <div className={"col-md-6 pb-3"}>
                <LabeledRadioInput
                  name={"scenarioKlantId"}
                  caption={"Weergeven scenario van"}
                  options={weergevenOptions}
                  onChange={event => {
                    onKlantIdChange(event.target.value);
                    setFieldValue("scenarioKlantId", event.target.value);
                  }}
                  layout={Direction.Horizontal}
                />
              </div>
            )}
          </Formik>
        )}
        <Table striped bordered hover responsive className="scenario__table mb-4">
          <colgroup>
            <col style={{ width: "70%" }} />
            <col style={{ width: "30%", textAlign: "right" }} />
          </colgroup>
          <thead>
            <tr>
              <th colSpan={2}>Uw inkomen volgend jaar {getNaam(aanvrager ?? null, "Aanvrager 1")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ANW</td>
              <td>{bedragFormatNull(inkomensData?.anwUitkeringBedrag)}</td>
            </tr>
            <tr>
              <td>Nabestaandenpensioen</td>
              <td>{bedragFormatNull(inkomensData?.nabestaandenpensioen, monthDivision)}</td>
            </tr>
            <tr>
              <td>Wezenpensioen</td>
              <td>{bedragFormatNull(inkomensData?.wezenpensioen, monthDivision)}</td>
            </tr>
            <tr>
              <td>Inkomensaanvulling uit overlijdensrisicoverzekering</td>
              <td>{bedragFormatNull(inkomensaanvulling)}</td>
            </tr>
            {motiveringInkomensanalyse?.aanvrager2 && (
              <tr>
                <td>Inkomen {getNaam(partner ?? null, "Aanvrager 2")} (blijft 100% werken)</td>
                <td>{bedragFormatNull(inkomensData?.inkomenPartner, monthDivision)}</td>
              </tr>
            )}
            <tr className="font-weight-bold">
              <td>Inkomen totaal</td>
              <td>{bedragFormatNull(inkomensData?.totaalInkomen)}</td>
            </tr>
            <tr className="table-warning">
              <td>Inkomensverlies</td>
              <td>{bedragFormatNull(inkomensData?.inkomensverlies)}</td>
            </tr>
          </tbody>
        </Table>

        {brutoInkomensWensenWeergeven && (
          <BrutoInkomensWensenTable
            brutoInkomensWensPercentage={brutoInkomensWensPercentage}
            jaarlijkseAnalyse={jaarlijkseAnalyse}
            gewenstInkomenBedrag={jaarlijkseAnalyse?.gewenstInkomen || 0}
          />
        )}

        {controleWerkelijkeLastenWeergeven && <ControleWerkelijkeLastenTable {...inkomensanalyseResultaten?.[0]} />}

        {controleMaandlast && controleMaandlastKeuze === ControleMaandlastOptions.BenodigdInkomen && (
          <BenodigdInkomenTable {...inkomensanalyseResultaten?.[0]} />
        )}

        {maximaleHypotheekWeergeven && <MaximaleHypotheekTable {...inkomensanalyseResultaten?.[0]} />}

        {controleMaandlast && (
          <ControleMaandlastTable conclusie={conclusie} controleMaandlastKeuze={controleMaandlastKeuze} />
        )}

        <p className="mt-3 px-2">
          {voetnoten?.map(footnote => (
            <CitationNote key={footnote.type} type={footnote.type} text={footnote.toelichting || ""} />
          ))}
        </p>

        {nbiWeergeven && <TableNettoBesteedbaarInkomen nbi={nbi} />}

        {benodigdKapitaalWeergeven && (
          <BenodigdKapitaalTable
            benodigdKapitaal={benodigdKapitaal}
            benodigdKapitaalOmschrijving={benodigdKapitaalOmschrijving}
          />
        )}
      </div>
    </CitationProvider>
  );
};

export default InkomensanalyseTabelOverlijden;
