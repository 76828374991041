import { LocalDate } from "@js-joda/core";
import {
  FiscaleVoortzettingKeuze,
  HypotheekAutomatischeRentedaling,
  HypotheekFiscaleRegeling,
  FiscaleVoortzettingOptions,
  HypotheekHuidigeSituatie as HypotheekHuidigeSituatieDlEntry,
  HypotheekHuidigeSituatieLeningdeel,
  HypotheekHuidigeSituatieOutput as HypotheekhuidigeSituatieDlOutput,
  HypotheekHypotheeklabel,
  HypotheekKapitaalopbouw,
  HypotheekoptiesIngLeningdeel,
  HypotheekoptiesIngPriceTool,
  HypotheekPand,
  EnergieKlasseType,
  GebruikPandSoort,
  HypotheekPremie,
  BetalingsTermijnType,
  HypotheekPremiedepot,
  HypotheekRenteaftrek,
  HypotheeksamenstellingLeningdeel,
  HypotheekVoorstel as HypotheekVoorstelDlEntry,
  HypotheekVoorstelLeningdeel,
  HypotheekVoorstelOutput as HypotheekVoorstelDlOutput,
  Financieringsoort,
  Hypotheekvorm,
  AflossingsVormType,
  KlantnaamReadOnly,
  PeriodiekeAflossingInput,
  HypotheekProductwaarde
} from "../../.generated/forms/formstypes";
import { mapProductDl2Ui } from "../../producten-overzicht/infra/product-mapper-dl-2-ui";
import { premieGegevensSchema } from "../../producten-overzicht/infra/producten-overzicht-schema";
import {
  FiscalegegevensType,
  PremieDepotModalType,
  PremieGegevensType
} from "../../producten-overzicht/infra/producten-overzicht-types";
import { mapJaarMaandInputFromLooptijdDl2Ui } from "../../shared/generic-parts/jaar-maand/map-dl-2-ui";
import { mapKlantnaamDl2Ui } from "../../shared/generic-parts/klantnaam/map-dl-2-ui";
import { AanvragerKeuze, FieldMap, mapBerekenInput, UiName } from "../../shared/types";
import { createMapToUi } from "../../shared/utils/create-map-to-ui";
import { prefixWithZero } from "../../shared/utils/helpers";
import { target2field } from "../../shared/utils/target-to-field";
import { SoortOpnamesUitkering } from "../opnames/opnames-types";
import { getHypotheekTextResources } from "./hypotheek-resources";
import {
  hypotheekFiscaleRegelingSchema,
  hypotheekOptiesIngPriceToolLeningdeelSchema,
  hypotheekOptiesIngPriceToolSchema,
  hypothekenBaseSchema,
  kapitaalopbouwSchema,
  pandSchema,
  rentedalingObvMarktwaardePercentageModalSchema,
  productwaardeSchema
} from "./hypotheek-schema";
import {
  ExtraAflossingen,
  ExtraAflossingenModalType,
  FiscaleVoortzettingKeuzeType,
  GekoppeldProductType,
  HypotheekFiscaleRegelingType,
  HypotheekOptiesType,
  HypotheekOptieType,
  HypotheekProductDetailsType,
  HypotheekSelectieSamenstellingType,
  HypotheekType,
  HypotheekVormType,
  HypothekenState,
  KapitaalopbouwType,
  LeningdeelgegevensType,
  PandType,
  RenteAftrek,
  RentedalingObvMarktwaardePercentageModalType,
  RentedalingPercentagesType,
  RenteScenarioModalType,
  SchuldenaarsType,
  SpecificatieRenteaftrekModalType,
  VerzekerdenType,
  VerzekeringnemersType,
  HypotheeklabelType
} from "./hypotheek-types";
import { mapStringToLocalDate } from "adviesbox-shared";
import { convertUtcToTimeZone } from "adviesbox-shared";

function isHypotheekHuidigeSituatie(arg: any): arg is HypotheekHuidigeSituatieDlEntry {
  return arg.panden !== undefined;
}

function isHypotheekVoorstel(arg: any): arg is HypotheekVoorstelDlEntry {
  return arg.pand !== undefined;
}

function mapFiscaleVoortzettingKeuzes(
  fiscaleVoortzettingDl: FiscaleVoortzettingKeuze[] | null
): FiscaleVoortzettingKeuzeType[] | null {
  if (fiscaleVoortzettingDl) {
    return fiscaleVoortzettingDl
      .filter(keuze => !!keuze.productId)
      .map(f => ({
        omschrijving: f.omschrijving,
        productId: f.productId,
        productkenmerken: f.productkenmerken && {
          ...f.productkenmerken,
          einddatum: f.productkenmerken?.einddatum
            ? convertUtcToTimeZone(f.productkenmerken?.einddatum)?.toLocalDate() || null
            : null,
          oorspronkelijkeIngangsdatum: f.productkenmerken?.oorspronkelijkeIngangsdatum
            ? convertUtcToTimeZone(f.productkenmerken?.oorspronkelijkeIngangsdatum)?.toLocalDate() || null
            : null,
          ingangsdatumBox1: f.productkenmerken?.ingangsdatumBox1
            ? convertUtcToTimeZone(f.productkenmerken?.ingangsdatumBox1)?.toLocalDate() || null
            : null,
          originelePolisnummer: null
        }
      }));
  }

  return fiscaleVoortzettingDl;
}

export type LeningdeelHuidigVoorstelType = HypotheekHuidigeSituatieLeningdeel | HypotheekVoorstelLeningdeel;
export function isHypotheekHuidigLeningdeel(
  arg: LeningdeelHuidigVoorstelType | HypotheeksamenstellingLeningdeel
): arg is HypotheekHuidigeSituatieLeningdeel {
  return (arg as HypotheekHuidigeSituatieLeningdeel).pandId !== undefined;
}

export function mapHypotheekVormDl2Ui(hypotheekVorm: Hypotheekvorm): HypotheekVormType {
  return {
    code: hypotheekVorm.code,
    omschrijving: hypotheekVorm.omschrijving || "",
    aflossingsvorm: hypotheekVorm.aflossingsvorm || AflossingsVormType.Lineair,
    isStartersLening: hypotheekVorm.starterslening || false,
    isRestschuldLening: hypotheekVorm.restschuldFinanciering
  };
}

function mapExtraAflossingenDl2Ui(
  leningdeelDl: LeningdeelHuidigVoorstelType | HypotheeksamenstellingLeningdeel | null
): ExtraAflossingenModalType {
  const extraAflossingenUi: ExtraAflossingen[] = [];
  if (leningdeelDl?.extraAflossingen) {
    leningdeelDl?.extraAflossingen.map(c => {
      // negatieve aflossingen zijn 'opnames' (bij overwaarde hypotheek), in de popup daarom alleen aflossing met positief bedrag.
      if (c.bedrag >= 0 || c.bedrag === null)
        extraAflossingenUi.push({
          datum: c.datum ? mapStringToLocalDate(c.datum) : null,
          bedrag: c.bedrag || null
        });
    });

    return { extraAflossingen: extraAflossingenUi };
  }
  return {} as ExtraAflossingenModalType;
}

function mapHypotheekOptiesDl2Ui(hypotheekLabel: HypotheekHypotheeklabel | null): HypotheekOptieType {
  const hypotheekOptiesUi: HypotheekOptiesType[] = [];
  if (hypotheekLabel?.hypotheekopties) {
    hypotheekLabel?.hypotheekopties.map(c =>
      hypotheekOptiesUi.push({
        code: c.code,
        omschrijving: c.omschrijving,
        toelichting: c.toelichting,
        geselecteerd: c.geselecteerd,
        rentekortingPercentage: c.rentekortingPercentage,
        hypotheekLabelCode: hypotheekLabel.code,
        maatschappijCode: hypotheekLabel.maatschappijCode,
        default: null,
        verplicht: null
      })
    );
    return { hypotheekOpties: hypotheekOptiesUi };
  }
  return {} as HypotheekOptieType;
}

const mapHypotheekOptiesIngLeningDeel = createMapToUi(hypotheekOptiesIngPriceToolLeningdeelSchema).from<
  HypotheekoptiesIngLeningdeel
>({
  meenemen: v => v.doorlopendLeningdeelMeenemen,
  loyaliteitsbonusBedrag: v => v.loyaliteitsbonusBedrag,
  standaardRenteData: v => v.standaardRenteData,
  ltvData: v => v.ltvData,
  dagrenteData: v => v.dagrenteData,
  actieveBetaalrekeningData: v => v.actieveBetaalrekeningData,
  loyaliteitsbonusData: v => v.loyaliteitsbonusData,
  teBetalenRenteData: v => v.teBetalenRenteData,
  optimalisatieMelding: v => v.optimalisatiemelding,
  errorMelding: _ => null
});

const mapHypotheekOptiesIng = createMapToUi(hypotheekOptiesIngPriceToolSchema).from<HypotheekoptiesIngPriceTool>({
  actieveBetaalRekening: v => v.actieveBetaalrekening,
  dagrente: v => v.dagrente,
  loyaliteitsbonus: v => v.loyaliteitsbonus
});

function mapRentedalingPercentages(
  automatischeRentedaling: HypotheekAutomatischeRentedaling
): RentedalingPercentagesType[] {
  const rentedalingPercentageUI: RentedalingPercentagesType[] = [];
  if (automatischeRentedaling?.renteopslagen) {
    automatischeRentedaling.renteopslagen.forEach(c =>
      rentedalingPercentageUI.push({
        marktwaardePercentageTotEnMet: c.marktwaardeTmPercentage,
        renteopslagPercentage: c.renteopslagPercentage ?? 0
      })
    );
    return rentedalingPercentageUI;
  }
  return [];
}

const mapAutomatischeRentedalingModal = (
  leningdeel: LeningdeelHuidigVoorstelType | HypotheeksamenstellingLeningdeel
): RentedalingObvMarktwaardePercentageModalType => {
  const result = rentedalingObvMarktwaardePercentageModalSchema.default();
  if (leningdeel.automatischeRentedaling) {
    result.aanpassingRente = leningdeel.automatischeRentedaling.aanpassingRente;
    result.renteScenario = leningdeel.automatischeRentedaling.rentescenario || false;
    result.opslagAfslagNaRentevastperiode = leningdeel.automatischeRentedaling.opslagNaRentevastperiodePercentage;
    result.rentedalingPercentages = mapRentedalingPercentages(leningdeel.automatischeRentedaling);
    result.marktwaardeRenteBedrag = leningdeel.automatischeRentedaling.marktwaardeRenteBedrag;
    result.automatischeRentedalingEnabled = null;
    // TODO: Rente opslagen
  }
  return result;
};

const mapRenteScenarioModal = (
  leningdeel: LeningdeelHuidigVoorstelType | HypotheeksamenstellingLeningdeel
): RenteScenarioModalType => {
  const result: RenteScenarioModalType = { renteScenario: [], vervolgjarenAutomatischInvullen: false };
  // Vullen van het scenario a.d.h.v. indexeringen (vanaf maand + percentage)
  for (let index = 0; index < 30; index++) {
    result.renteScenario.push({
      bedrag: null,
      percentage:
        leningdeel.renteScenario &&
        leningdeel.renteScenario.indexeringen &&
        leningdeel.renteScenario.indexeringen.length
          ? leningdeel.renteScenario.indexeringen
              .filter(f => f.ingangsmaand != null && Math.ceil(f.ingangsmaand / 12) <= index + 1)
              .sort(function(a, b) {
                return (b.ingangsmaand || 0) - (a.ingangsmaand || 0);
              })
              .map(m => m.percentage)[0]
          : leningdeel.renteScenario?.waarde ?? null
    });
  }
  return result;
};

export function mapLeningdeelGegevensDl2Ui(
  leningdeel: LeningdeelHuidigVoorstelType | HypotheeksamenstellingLeningdeel
): LeningdeelgegevensType {
  const result: LeningdeelgegevensType = {
    leningDeelId: leningdeel.leningdeelId,
    oorspronkelijkeHoofdsom: null,
    garantie: null,
    renteVariant: leningdeel.rentevariant,
    leningdeelHoofdsom: mapBerekenInput(leningdeel.leningdeelBedrag, null, null),
    rentevastPeriodeJaar:
      typeof leningdeel.rentevastAantalMaanden === "number" ? leningdeel.rentevastAantalMaanden / 12 : null,
    rentevastperiodeKeuze:
      typeof leningdeel.rentevastAantalMaanden === "number" ? leningdeel.rentevastAantalMaanden / 12 : null,
    renteBedenktijdJaar:
      typeof leningdeel.rentebedenktijdInMaanden === "number" ? leningdeel.rentebedenktijdInMaanden / 12 : null,
    einddatum: mapStringToLocalDate(leningdeel.rentevastperiodeEinddatum) || null,
    automatischeRentedaling: leningdeel.automatischeRentedaling !== null,
    automatischeRentedalingModal: mapAutomatischeRentedalingModal(leningdeel),
    extraAflossing: mapExtraAflossingenDl2Ui(leningdeel),
    renteScenarioModal: mapRenteScenarioModal(leningdeel),
    renteVariantenWaarschuwingText: getHypotheekTextResources("renteVastPeriodeWaarschuwing"),
    rentePercentage: {
      bedrag: leningdeel.renteScenario?.waarde ?? null,
      berekendBedrag: leningdeel.renteScenario?.waarde ?? null,
      berekenen: true
    },
    datumOpgave: mapStringToLocalDate(leningdeel.opgaveDatum),
    periodiekeAflossing: leningdeel.aflossingBedrag
  };

  if (isHypotheekHuidigLeningdeel(leningdeel)) {
    const oorspronkelijkeHoofdsom =
      typeof leningdeel.oorspronkelijkeHoofdsomBedrag === "number" ? leningdeel.oorspronkelijkeHoofdsomBedrag : null;
    result.oorspronkelijkeHoofdsom = oorspronkelijkeHoofdsom;
    result.leningdeelHoofdsom = mapBerekenInput(
      leningdeel.leningdeelBedrag,
      typeof leningdeel.restantHoofdsomOvernemen === "boolean" ? leningdeel.restantHoofdsomOvernemen : null,
      oorspronkelijkeHoofdsom
    );
    result.datumOpgave = mapStringToLocalDate(leningdeel.opgaveDatum);
    result.garantie = leningdeel.garantie || null;
  }

  return result;
}

function mapProductDetailsDl2Ui(hypotheekDl: HypotheekHuidigeSituatieLeningdeel): HypotheekProductDetailsType {
  return {
    hypotheekOpWoning: hypotheekDl.pandId,
    rangorde: hypotheekDl.rangorde || 1
  };
}

function mapPandDl2Ui(pand: HypotheekPand, voorstelHypotheekBedrag?: number | null): PandType {
  if (!pand) return pandSchema.default();
  return {
    pandId: pand.pandId,
    marktwaardeRenteBedrag: pand.marktwaardeRenteBedrag,
    bevoorschottingspercentage: pand.bevoorschottingspercentage,
    gebruikPand: pand.gebruikPand || GebruikPandSoort.PrimaireWoning,
    marktwaardeLtv: mapBerekenInput(
      pand.marktwaardeLtvBedrag,
      pand.marktwaardeLtvOvernemen,
      pand.marktwaardeLtvBerekendBedrag
    ),
    totaleHypotheekBedrag: voorstelHypotheekBedrag ?? pand.totaleHypotheekBedrag,
    bewoners: (pand.bewoners as AanvragerKeuze | null) ?? AanvragerKeuze.Aanvrager1
  };
}
function mapKapitaalopbouwDl2Ui(kapitaalopbouw: HypotheekKapitaalopbouw | null): KapitaalopbouwType {
  if (!kapitaalopbouw) return kapitaalopbouwSchema.default();
  return {
    doelkapitaal1Bedrag: kapitaalopbouw.doelkapitaal1Bedrag,
    doelkapitaal2Bedrag: kapitaalopbouw.doelkapitaal1Bedrag,
    doelkapitaal1Percentage: kapitaalopbouw.doelkapitaal1Percentage,
    doelkapitaal2Percentage: kapitaalopbouw.doelkapitaal1Percentage,
    doelkapitaal1Overnemen: kapitaalopbouw.doelkapitaal1Overnemen,
    doelkapitaal2Overnemen: kapitaalopbouw.doelkapitaal1Overnemen,
    voorbeeldkapitaal1Bedrag: kapitaalopbouw.voorbeeldkapitaal1Bedrag,
    voorbeeldkapitaal2Bedrag: kapitaalopbouw.voorbeeldkapitaal1Bedrag,
    voorbeeldkapitaal1Percentage: kapitaalopbouw.voorbeeldkapitaal1Percentage,
    voorbeeldkapitaal2Percentage: kapitaalopbouw.voorbeeldkapitaal1Percentage
  };
}

function mapFiscaleRegelingDl2Ui(fiscaleRegeling: HypotheekFiscaleRegeling | null): HypotheekFiscaleRegelingType {
  if (!fiscaleRegeling) return hypotheekFiscaleRegelingSchema.default();
  return {
    productId: fiscaleRegeling.productId,
    polisnummer: fiscaleRegeling.polisnummer,
    originelePolisnummer: fiscaleRegeling.originelePolisnummer,
    kapitaalopbouw: fiscaleRegeling.kapitaalopbouw,
    fiscaleVoortzetting:
      fiscaleRegeling.productId || fiscaleRegeling.fiscaleVoortzetting || FiscaleVoortzettingOptions.Geen,
    externeMaatschappijCode: fiscaleRegeling.externeMaatschappijCode,
    externeMaatschappijOmschrijving: fiscaleRegeling.externeMaatschappijOmschrijving,
    lijfrenteclausuleOrigineel: fiscaleRegeling.lijfrenteclausuleOrigineel,
    fiscaleTypering: fiscaleRegeling.fiscaleTypering,
    garantieverzekering: fiscaleRegeling.garantieverzekering,
    fiscaalRegime: fiscaleRegeling.fiscaalRegime,
    oorspronkelijkeIngangsdatum: mapStringToLocalDate(fiscaleRegeling.oorspronkelijkeIngangsdatum),
    ingangsdatumBox1: mapStringToLocalDate(fiscaleRegeling.ingangsdatumBox1),
    einddatum: mapStringToLocalDate(fiscaleRegeling.einddatum),
    doelkapitaalBedrag: fiscaleRegeling.doelkapitaalBedrag,
    laagstePremieooitBedrag: fiscaleRegeling.laagstePremieooitBedrag,
    hoogstePremieOoitBedrag: fiscaleRegeling.hoogstePremieOoitBedrag,
    huidigeJaarPremieBedrag: fiscaleRegeling.huidigeJaarPremieBedrag,
    premieLopendJaarBedrag: fiscaleRegeling.premieLopendJaarBedrag,
    eerdereUitkeringenBedrag: fiscaleRegeling.eerdereUitkeringenBedrag,
    ingebrachteWaardeBedrag: fiscaleRegeling.ingebrachteWaardeBedrag
  };
}

function mapPremieDepotDl2Ui(premieDepot: HypotheekPremiedepot | null): PremieDepotModalType | null {
  if (!premieDepot) return null;
  return {
    uitgangspunt: premieDepot.uitgangspunt,
    aanvangsstorting: premieDepot.opnameAanvangsstorting,
    bedrag: premieDepot.premiedepotBedrag,
    duur: premieDepot.premiedepotDuurInMaanden,
    extraStortingen: premieDepot.opnameExtraStortingen,
    hogePremies: premieDepot.opnameHogePremies,
    lagePremies: premieDepot.opnameLagePremies,
    vergoeding: premieDepot.vergoeding,
    aanvangsstortingBedrag: null,
    extraPremieStortingen: null,
    hoogInlegBedrag: null,
    hoogLaagDuur: null,
    inlegBedrag: null,
    premieDuur: null
  };
}

function mapPremieDl2Ui(premie: HypotheekPremie | null): PremieGegevensType {
  if (!premie) return premieGegevensSchema.default();
  return {
    ...premieGegevensSchema.default(),
    premiedepot: mapPremieDepotDl2Ui(premie.premiedepot),
    aanvangExtraPremieStortingenBedrag: premie.aanvangsstorting,
    betalingstermijn: premie.betalingsTermijn as BetalingsTermijnType,
    einddatumPremieBetaling: mapStringToLocalDate(premie.einddatumStortingen),
    hoogLaagEinddatum: mapStringToLocalDate(premie.einddatumStortingenHoogLaag),
    hoogLaagVerhouding: premie.hooglaagVerhouding,
    spaarPremieLaag: premie.premieBedrag,
    totalePremieHoog: premie.premieTotaalBedragHoog,
    totalePremieLaag: premie.premieTotaalBedragLaag,
    hoogLaagLooptijd: mapJaarMaandInputFromLooptijdDl2Ui(premie.premieduurHooglaagInMaanden),
    looptijd: mapJaarMaandInputFromLooptijdDl2Ui(premie.premieduurInMaanden)
  };
}
function mapRenteaftrekkenkDl2Ui(renteaftrekkenDl: HypotheekRenteaftrek[] | null): SpecificatieRenteaftrekModalType {
  const renteAftrekkenUi: RenteAftrek[] = [];

  if (renteaftrekkenDl) {
    renteaftrekkenDl.map(c =>
      renteAftrekkenUi.push({
        aanvangsdatum: c.aanvangsdatum ? mapStringToLocalDate(c.aanvangsdatum) : null,
        einddatum: c.einddatum ? mapStringToLocalDate(c.einddatum) : null,
        bedrag: c.bedrag || null
      })
    );

    return { renteAftrekken: renteAftrekkenUi };
  }
  return {} as SpecificatieRenteaftrekModalType;
}

export function mapRenteaftrekAanvangstdatum(renteaftrekken: HypotheekRenteaftrek[] | null): LocalDate | null {
  if (renteaftrekken && renteaftrekken.length > 0) {
    const renteAftrek = renteaftrekken
      .map(function(e) {
        return e.aanvangsdatum ? mapStringToLocalDate(e.aanvangsdatum) : null;
      })
      .sort();
    return renteAftrek.find(c => c) || null;
  }
  return null;
}

export function mapRenteaftrekEinddatum(renteaftrekken: HypotheekRenteaftrek[] | null): LocalDate | null {
  if (renteaftrekken && renteaftrekken.length > 0) {
    const renteAftrek = renteaftrekken
      .map(function(e) {
        return e.einddatum ? mapStringToLocalDate(e.einddatum) : null;
      })
      .sort();
    return renteAftrek.find(c => c) || null;
  }
  return null;
}

function mapFiscaleGegevensDl2Ui(
  hypotheekDl: HypotheekHuidigeSituatieLeningdeel | HypotheekVoorstelLeningdeel | HypotheeksamenstellingLeningdeel
): FiscalegegevensType {
  const renteaftrekken = hypotheekDl.renteaftrekken || null;
  const deelBox1BedragVoorBerekening = hypotheekDl.deelBox1Bedrag || 0;

  return {
    deelBox1Bedrag: hypotheekDl.deelBox1Bedrag || 0,
    deelBox1Percentage: hypotheekDl.leningdeelBedrag
      ? (deelBox1BedragVoorBerekening / hypotheekDl.leningdeelBedrag) * 100
      : 100,
    deelBox3Bedrag: hypotheekDl.leningdeelBedrag ? hypotheekDl.leningdeelBedrag - deelBox1BedragVoorBerekening : 0,
    deelBox3Percentage: hypotheekDl.leningdeelBedrag
      ? ((hypotheekDl.leningdeelBedrag - deelBox1BedragVoorBerekening) / hypotheekDl.leningdeelBedrag) * 100
      : 0,
    renteaftrekSpecificatie: mapRenteaftrekkenkDl2Ui(renteaftrekken),
    begindatumRenteaftrek: mapRenteaftrekAanvangstdatum(renteaftrekken),
    einddatumRenteaftrek: mapRenteaftrekEinddatum(renteaftrekken),
    consumptiefBedrag: hypotheekDl.consumptiefBedrag,
    bevatOpnames: !!hypotheekDl.extraAflossingen?.find(c => c.bedrag < 0)
  };
}

const mapKlantenNaarAanvragerKeuze = (
  klantIds: string[],
  aanvrager1: KlantnaamReadOnly | null,
  aanvrager2: KlantnaamReadOnly | null
): AanvragerKeuze => {
  let verzekeringnemers = AanvragerKeuze.Aanvrager1;

  if (klantIds.length > 1) {
    verzekeringnemers = AanvragerKeuze.Beiden;
  } else if (klantIds[0] === aanvrager1?.klantId) {
    verzekeringnemers = AanvragerKeuze.Aanvrager1;
  } else if (klantIds[0] === aanvrager2?.klantId) {
    verzekeringnemers = AanvragerKeuze.Aanvrager2;
  }

  return verzekeringnemers;
};

function mapVerzekeringnemersDl2Ui(
  hypotheekDl: HypotheekVoorstelLeningdeel,
  aanvrager1: KlantnaamReadOnly | null,
  aanvrager2: KlantnaamReadOnly | null
): VerzekeringnemersType {
  return {
    verzekeringnemer: mapKlantenNaarAanvragerKeuze(hypotheekDl.verzekeringnemerKlantIds, aanvrager1, aanvrager2)
  };
}

function mapVerzekerdenDl2Ui(
  hypotheekDl: HypotheekVoorstelLeningdeel,
  aanvrager1: KlantnaamReadOnly | null,
  aanvrager2: KlantnaamReadOnly | null
): VerzekerdenType {
  return { verzekerde: mapKlantenNaarAanvragerKeuze(hypotheekDl.verzekerdeKlantIds, aanvrager1, aanvrager2) };
}

function mapSchuldenaarsDl2Ui(
  hypotheekDl: HypotheekVoorstelLeningdeel | HypotheekHuidigeSituatieLeningdeel | HypotheeksamenstellingLeningdeel,
  klantIdAanvrager2?: string | null | undefined
): SchuldenaarsType {
  if (hypotheekDl.klantIds) {
    return {
      schuldenaar:
        hypotheekDl.klantIds.length > 1
          ? AanvragerKeuze.Beiden
          : hypotheekDl.klantIds.length === 1 && klantIdAanvrager2 && hypotheekDl.klantIds[0] === klantIdAanvrager2
          ? AanvragerKeuze.Aanvrager2
          : AanvragerKeuze.Aanvrager1
    };
  }

  return { schuldenaar: AanvragerKeuze.Aanvrager1 };
}

function mapGekoppeldeProductDl2Ui(
  hypotheekDl: HypotheekVoorstelLeningdeel | HypotheekHuidigeSituatieLeningdeel,
  values: HypotheekVoorstelDlEntry | HypotheekHuidigeSituatieDlEntry
): GekoppeldProductType {
  return {};
}

export function mapHypotheekSamenstellingProductDlToUi(
  productDl: HypotheeksamenstellingLeningdeel
): HypotheekSelectieSamenstellingType {
  const singleForm =
    (productDl.matchHypotheekvormen && productDl.matchHypotheekvormen.length > 1) ||
    (productDl.nearMatchHypotheekvormen && productDl.nearMatchHypotheekvormen.length > 1);
  return {
    nearMatchHypotheekvormen: singleForm
      ? productDl.nearMatchHypotheekvormen?.map(c => mapHypotheekVormDl2Ui(c)) ?? null
      : null,
    matchHypotheekvormen: singleForm
      ? productDl.matchHypotheekvormen?.map(c => mapHypotheekVormDl2Ui(c)) ?? null
      : null,
    huidigeHypotheekvorm: productDl.huidigeHypotheekvorm ? mapHypotheekVormDl2Ui(productDl.huidigeHypotheekvorm) : null,
    productCode: prefixWithZero(productDl.productcode) || "",
    hypotheekVorm: productDl.hypotheekvorm ? mapHypotheekVormDl2Ui(productDl.hypotheekvorm) : null,
    product: {
      ...mapProductDl2Ui(productDl),
      renteboxCode: productDl.hypotheekvorm ? productDl.hypotheekvorm.renteboxCode : null,
      betreftOpname:
        productDl.hypotheekvorm && productDl.hypotheekvorm.aflossingsvorm === AflossingsVormType.KredietNoPay
    },
    leningdeelgegevens: mapLeningdeelGegevensDl2Ui(productDl),
    fiscaleRegeling: productDl.fiscaleRegeling ? mapFiscaleRegelingDl2Ui(productDl.fiscaleRegeling) : null,
    fiscalegegevens: mapFiscaleGegevensDl2Ui(productDl),
    schuldenaars: mapSchuldenaarsDl2Ui(productDl, null),
    isNieuw: true,
    volgnummer: productDl.volgnummer,
    hypotheekProductDetails: null
  };
}

const mapProductwaardeDl2Ui = createMapToUi(productwaardeSchema).from<HypotheekProductwaarde>({
  waardeopbouwBedrag: v => v.waardeopbouwBedrag,
  waardeopbouwMaanden: v => v.waardeopbouwMaanden
});

function mapHypotheekDl2Ui(
  productDl: HypotheekHuidigeSituatieLeningdeel | HypotheekVoorstelLeningdeel,
  values: HypotheekVoorstelDlEntry | HypotheekHuidigeSituatieDlEntry,
  index: number,
  energyLabel: EnergieKlasseType | null
): HypotheekType {
  const klantIdAanvrager2 = values.aanvrager2 && values.aanvrager2.klantId;

  const opname = productDl.extraAflossingen?.find(c => c.bedrag < 0);

  const hypotheek: HypotheekType = {
    nearMatchHypotheekvormen: null,
    volgnummer: index + 1,
    partijCode: productDl.maatschappijCode || "",
    renteBoxSoort: productDl.renteboxSoort,
    energieKlasse: energyLabel,
    labelCode: isHypotheekVoorstel(values) ? values.hypotheeklabel && values.hypotheeklabel.code : null,
    labelNaam: isHypotheekVoorstel(values) ? (values.hypotheeklabel && values.hypotheeklabel.omschrijving) || "" : "",
    productCode: prefixWithZero(productDl.productcode) || "",
    hypotheekVorm: mapHypotheekVormDl2Ui(productDl.hypotheekvorm),
    marktwaardeWaarschuwingTonen: false,
    product: {
      ...mapProductDl2Ui(productDl, {
        energyKlasse: energyLabel,
        hypotheekVoorstel: "soortFinanciering" in values ? values.soortFinanciering : null,
        meerwerkEBV: "pand" in values && values.pand ? values.pand.meerwerkEbvBedrag : null
      }),
      betreftOpname: !!productDl.extraAflossingen?.find(c => c.bedrag < 0),
      leningdeelnummer: productDl.leningdeelnummer
    },
    hypotheekProductDetails: isHypotheekHuidigLeningdeel(productDl) ? mapProductDetailsDl2Ui(productDl) : null,
    leningdeelgegevens: mapLeningdeelGegevensDl2Ui(productDl),
    fiscalegegevens: mapFiscaleGegevensDl2Ui(productDl),
    opnameBedrag: opname?.bedrag || null,
    opnameMaanden: opname?.looptijdInMaanden || null,
    opnameSoort: opname?.looptijdInMaanden
      ? opname?.looptijdInMaanden === 1
        ? SoortOpnamesUitkering.Eenmalig
        : SoortOpnamesUitkering.Periodiek
      : null,
    renteBoxMaatschappijCode: null,
    schuldenaars: mapSchuldenaarsDl2Ui(productDl, klantIdAanvrager2),
    gekoppeldProduct: mapGekoppeldeProductDl2Ui(productDl, values),
    opmerking: {},
    verzekerde: isHypotheekVoorstel(values)
      ? mapVerzekerdenDl2Ui(productDl as HypotheekVoorstelLeningdeel, values.aanvrager1, values.aanvrager2)
      : { verzekerde: AanvragerKeuze.Aanvrager1 },
    verzekeringnemers: isHypotheekVoorstel(values)
      ? mapVerzekeringnemersDl2Ui(productDl as HypotheekVoorstelLeningdeel, values.aanvrager1, values.aanvrager2)
      : { verzekeringnemer: AanvragerKeuze.Aanvrager1 },
    kapitaalopbouw: isHypotheekVoorstel(values)
      ? (productDl as HypotheekVoorstelLeningdeel).kapitaalopbouw
        ? mapKapitaalopbouwDl2Ui((productDl as HypotheekVoorstelLeningdeel).kapitaalopbouw)
        : null
      : null,
    fiscaleRegeling: isHypotheekVoorstel(values)
      ? (productDl as HypotheekVoorstelLeningdeel).fiscaleRegeling
        ? mapFiscaleRegelingDl2Ui((productDl as HypotheekVoorstelLeningdeel).fiscaleRegeling)
        : null
      : null,
    premieGegevens: isHypotheekVoorstel(values)
      ? (productDl as HypotheekVoorstelLeningdeel).premie
        ? mapPremieDl2Ui((productDl as HypotheekVoorstelLeningdeel).premie)
        : null
      : null,
    isNieuw: false,
    soortOnderpand: isHypotheekVoorstel(values)
      ? values.soortFinanciering || Financieringsoort.Geen
      : Financieringsoort.Geen,
    hypotheekOptiesIngPriceToolLeningdeel:
      isHypotheekVoorstel(values) && values.maatschappijCode === "IN"
        ? mapHypotheekOptiesIngLeningDeel((productDl as HypotheekVoorstelLeningdeel).hypotheekoptiesIngPriceTool)
        : null,
    productwaarde:
      isHypotheekVoorstel(values) && (productDl as HypotheekVoorstelLeningdeel).productwaarde
        ? mapProductwaardeDl2Ui((productDl as HypotheekVoorstelLeningdeel).productwaarde)
        : null
  };
  return hypotheek;
}

function mapHypotheeklabelDl2Ui(hypotheeklabel: HypotheekHypotheeklabel): HypotheeklabelType {
  return {
    partijCode: hypotheeklabel.maatschappijCode,
    labelCode: hypotheeklabel.code,
    labelNaam: hypotheeklabel.omschrijving
  };
}

function dl2ui(values: HypotheekVoorstelDlEntry | HypotheekHuidigeSituatieDlEntry): HypothekenState {
  const verklaringInkomen = (values as HypotheekVoorstelDlEntry).verklaringInkomen;
  const hypothekenState: Partial<HypothekenState> = {
    // 1. in huidige situatie, het hypotheekbedrag wat bij pand staat (geselecteerde pand)
    // 2. bij voorstel, is het het values.totaleHypotheekbedrag
    aanvrager1: mapKlantnaamDl2Ui(values.aanvrager1),
    aanvrager2: mapKlantnaamDl2Ui(values.aanvrager2),
    energieKlasse: "pand" in values && values.pand ? values.pand.energieKlasse : null,
    eigenwoningschuldBedrag: isHypotheekVoorstel(values) ? values.eigenwoningschuldBedrag || null : null,
    soortFinanciering: isHypotheekVoorstel(values)
      ? values.soortFinanciering || Financieringsoort.Geen
      : Financieringsoort.Geen,
    aanvangsdatum: isHypotheekVoorstel(values) ? mapStringToLocalDate(values.aanvangsdatum) : null,
    hypotheekOptie: isHypotheekVoorstel(values)
      ? mapHypotheekOptiesDl2Ui(values.hypotheeklabel)
      : { hypotheekOpties: [] },
    hypotheekOptiesIngPriceTool:
      isHypotheekVoorstel(values) && values.hypotheeklabel?.hypotheekoptiesIngPriceTool
        ? mapHypotheekOptiesIng(values.hypotheeklabel?.hypotheekoptiesIngPriceTool)
        : null,
    meerwerkEBV: "pand" in values && values.pand ? values.pand.meerwerkEbvBedrag : null,
    producten: values.leningdelen
      ? (values.leningdelen as (HypotheekVoorstelLeningdeel | HypotheekHuidigeSituatieLeningdeel)[]).map(
          (productDl: any, index: number): HypotheekType => {
            if ("pand" in values && values.pand) {
              return mapHypotheekDl2Ui(productDl, values, index, values.pand.energieKlasse);
            }
            return mapHypotheekDl2Ui(productDl, values, index, null);
          }
        )
      : [],
    nhg: isHypotheekVoorstel(values) ? values.nhg : null,
    overbruggingBedrag: "overbruggingBedrag" in values ? values.overbruggingBedrag : null,
    fiscaleVoortzettingKeuzes:
      "fiscaleVoortzettingKeuzes" in values ? mapFiscaleVoortzettingKeuzes(values.fiscaleVoortzettingKeuzes) : null,
    gewensteHypotheek: "gewensteHypotheekBedrag" in values ? values.gewensteHypotheekBedrag : null,
    voorwaardenSelectie: verklaringInkomen ? { verklaringInkomen } : undefined,
    benodigdHypotheekbedragIngevuld: isHypotheekHuidigeSituatie(values)
      ? !!values.oorspronkelijkHypotheekbedragIngevuld
      : null,
    hypotheeklabel:
      isHypotheekVoorstel(values) && values.hypotheeklabel ? mapHypotheeklabelDl2Ui(values.hypotheeklabel) : null
  };

  if (isHypotheekVoorstel(values)) {
    hypothekenState.panden = values.pand ? [mapPandDl2Ui(values.pand, values.totaleHypotheekBedrag)] : [];
  }

  if (isHypotheekHuidigeSituatie(values)) {
    hypothekenState.panden = values.panden ? values.panden.map(pand => mapPandDl2Ui(pand)) : [];
  }
  return hypothekenBaseSchema.cast(hypothekenState);
}

export function mapHypotheekDlToUi(
  hypotheekId: string,
  data: HypotheekhuidigeSituatieDlOutput | HypotheekVoorstelDlOutput
): HypothekenState | null {
  const hypotheek = data && data.hypotheken ? data.hypotheken[hypotheekId] : null;

  if (!hypotheek) {
    return null;
  }
  return dl2ui(hypotheek);
}

export function mapDlTargetToHuidigeHypotheekUiField(target: string): UiName | null {
  const map: FieldMap<HypotheekHuidigeSituatieDlEntry> = {};

  return target2field(map, target);
}

export function mapDlTargetToHypotheekUiField(target: string): UiName | null {
  const map: FieldMap<HypotheekHuidigeSituatieDlEntry | HypotheekVoorstelDlEntry> = {
    leningdelen: {
      aanvangsdatum: { label: "Ingangsdatum", field: "producten[i].product.ingangsdatum" },
      looptijdInMaanden: { label: "Looptijd", field: "producten[i].product.looptijd.jaren" },
      renteScenario: {
        waarde: { label: "Rentepercentage", field: "producten[i].product.rentePercentage.bedrag" }
      },
      opgaveDatum: { label: "Datum opgave", field: "producten[i].leningdeelgegevens.datumOpgave" },
      leningdeelBedrag: {
        label: "Oorspronkelijke hoofdsom",
        field: "producten[i].leningdeelgegevens.oorspronkelijkeHoofdsom"
      },
      extraAflossingen: {
        looptijdInMaanden: {
          label: "Looptijd in maanden",
          // Onzeker of dit de juiste koppeling met het field is. Het veld staat niet op het scherm en na aanpassing van de
          // backend wordt er ook geen melding op looptijdInMaanden meer gegeven.
          field: "producten[i].leningdeelgegevens.extraAflossing.extraAflossingen[i].looptijdInMaanden"
        }
      }
    }
  };
  return target2field(map, target);
}

export const mapDlTargetToHypotheekUiFieldPeriodiekeAflossing = (selected: number) => (
  target: string
): UiName | null => {
  const map: FieldMap<PeriodiekeAflossingInput> = {
    rentePercentage: { label: "RentePercentage", field: `producten[${selected}].leningdeelgegevens.rentePercentage` },
    aanvangsdatum: { label: "Datum opgave", field: `producten[${selected}].ficalegegevens.datumOpgave` }
  };

  return target2field(map, target);
};
