import { LocalDate } from "@js-joda/core";
import {
  AdviesBoxColumn,
  Card,
  CardWrapper,
  DataGrid,
  mapJaarMaandInputFromLooptijdDl2Ui,
  PageLoading,
  PlatformFoutenSamenvatting,
  FormFirstFocus,
  insightsReactPlugin
} from "adviesbox-shared";
import { Form, FormikProps } from "formik";
import React, { ReactElement, useState } from "react";
import {
  SoortLijfrenteUitkeringOptions,
  SoortVermogenProductOptions,
  SoortVermogensrekeningOptions,
  SoortBerekeningOptions
} from "../.generated/forms/formstypes";
import { VermogensbeheerdersOutput } from "../.generated/instellingen-forms/instellingen-formstypes";
import { bepaalDefaultAflosproducten } from "../producten-overzicht/af-te-lossen-leningdelen-modal/bepaal-default-aflosproducten";
import { partijOnafhankelijk } from "../producten-overzicht/infra/product-constanten";
import {
  AlleMogelijkeProductBasisTypes,
  ProductSelectieType,
  SituatieSoort
} from "../producten-overzicht/infra/producten-overzicht-types";
import ProductSelectieAjax from "../producten-overzicht/product-selectie/product-selectie-ajax";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { SaveButton } from "../shared/components/save-button/save-button";
import { KredietType } from "../shared/generic-parts/krediet/schema";
import { LeningdeelType, VermogenLeningdeelType } from "../shared/generic-parts/leningdeel/schema";
import { useInstellingenBeheerPartijenData } from "../shared/hooks/use-instellingen-beheer-partijen-data";
import { assertNever } from "../shared/utils/helpers";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { bepaalVermogensKolommen } from "./bepaal-vermogens-kolommen";
import { vermogenSchema, vermogensSchema } from "./infra/vermogen-schema";
import { VermogensType, VermogenType } from "./infra/vermogen-types";
import VermogenDetails from "./vermogen-details/vermogen-details";
import { getIngangsdatum } from "../shared/utils/dates";
import { useFeature } from "adviesbox-shared";
import { Dashboard } from "../dashboard/dashboard";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

export type VermogenProps = VermogensType & { situatie: SituatieSoort };

const productcodeBankspaarhypotheekAEGON = 8;
const productcodeBankspaarhypotheekING = 12;

const alleenRelevanteLeningdeel = (leningdeel: LeningdeelType): boolean => {
  return (
    (leningdeel.maatschappijCode === "AE" && leningdeel.productcode === productcodeBankspaarhypotheekAEGON) ||
    (leningdeel.maatschappijCode === "IN" && leningdeel.productcode === productcodeBankspaarhypotheekING)
  );
};

export const vermogensbeheerdersFilter = (
  maatschappijCode: string,
  ingesteldeVermogensbeheerdersData: VermogensbeheerdersOutput | null
): boolean => {
  const actieveIngesteldeVermogensbeheerders =
    ingesteldeVermogensbeheerdersData?.vermogensbeheerders?.partijen?.filter(c => c.actief).map(c => c.code) || [];
  return actieveIngesteldeVermogensbeheerders?.includes(maatschappijCode);
};

// Geen Brand New Day tonen
// Alleen AEGON en ING tonen als er geen Bankspaarhypotheek van die maatschappij is.
export const vermogenFilter = (product: AlleMogelijkeProductBasisTypes, leningdelen: LeningdeelType[]): boolean => {
  const maatschappijCodes = leningdelen
    .filter(alleenRelevanteLeningdeel)
    .map(leningdeel => leningdeel.maatschappijCode);

  return product.maatschappijCode === partijOnafhankelijk || maatschappijCodes.includes(product.maatschappijCode);
};

export const newVermogenObjectFactory = (
  productSelectie: ProductSelectieType,
  options: {
    ingangsdatumVoorstel: LocalDate | null;
    kredieten: KredietType[];
    leningdelen: VermogenLeningdeelType[];
  },
  situatie: SituatieSoort
): VermogenType => {
  const nieuwVermogen = vermogenSchema.default();
  nieuwVermogen.soortProduct = productSelectie.codes.productVorm as SoortVermogenProductOptions;
  // De default van null is hier niet geldig
  nieuwVermogen.verpanding.indicatieveUitkerendeFaseSpecificatie.values.duurUitkering.jaren = 0;

  nieuwVermogen.product.wijzigingenInDoorlopendProductOvernemen = null;
  nieuwVermogen.product.ingangsdatum = getIngangsdatum(options.ingangsdatumVoorstel, situatie);

  switch (nieuwVermogen.soortProduct) {
    case SoortVermogenProductOptions.Beleggingsdepot:
      nieuwVermogen.product.einddatum = nieuwVermogen.product.ingangsdatum.plusYears(30);
      nieuwVermogen.kapitaalopbouw.soortRekening = SoortVermogensrekeningOptions.Belegging;
      nieuwVermogen.kapitaalopbouw.soortBerekening = SoortBerekeningOptions.Inleg;
      nieuwVermogen.verpanding.indicatieveUitkerendeFaseSpecificatie.values.soortLijfrenteUitkering =
        SoortLijfrenteUitkeringOptions.Levenslang;

      break;
    case SoortVermogenProductOptions.Betaalrekening:
      nieuwVermogen.kapitaalopbouw.soortRekening = null;
      nieuwVermogen.product.einddatum = null;
      nieuwVermogen.product.looptijd.jaren = null;
      nieuwVermogen.product.looptijd.maanden = null;
      break;
    case SoortVermogenProductOptions.Effectenlease:
      nieuwVermogen.product.einddatum = nieuwVermogen.product.ingangsdatum.plusYears(30);
      nieuwVermogen.kapitaalopbouw.soortRekening = SoortVermogensrekeningOptions.Belegging;
      nieuwVermogen.kapitaalopbouw.soortBerekening = SoortBerekeningOptions.Inleg;
      break;
    case SoortVermogenProductOptions.Spaarrekening:
      nieuwVermogen.product.einddatum = nieuwVermogen.product.ingangsdatum.plusYears(30);
      nieuwVermogen.kapitaalopbouw.soortRekening = null;
      nieuwVermogen.kapitaalopbouw.soortBerekening = SoortBerekeningOptions.Inleg;
      nieuwVermogen.verpanding.indicatieveUitkerendeFaseSpecificatie.values.soortLijfrenteUitkering =
        SoortLijfrenteUitkeringOptions.Levenslang;
      break;
    case SoortVermogenProductOptions.Product:
      const leningdeelVoorProduct = options.leningdelen
        .filter(alleenRelevanteLeningdeel)
        .find(leningdeel => leningdeel.maatschappijCode === productSelectie.codes.maatschappijCode);
      nieuwVermogen.kapitaalopbouw.soortBerekening = SoortBerekeningOptions.Inleg;
      if (leningdeelVoorProduct) {
        nieuwVermogen.kapitaalopbouw.doelkapitaalBedrag = leningdeelVoorProduct.doelkapitaal;
        nieuwVermogen.kapitaalopbouw.doelrendementPercentage = leningdeelVoorProduct.doelkapitaalPercentage;
        // Het vermogens product moet dezelfde looptijd hebben als de bankspaar hypotheek
        nieuwVermogen.product.ingangsdatum = leningdeelVoorProduct.aanvangsdatum;
        nieuwVermogen.product.looptijd = mapJaarMaandInputFromLooptijdDl2Ui(leningdeelVoorProduct.looptijd);
        nieuwVermogen.product.einddatum = leningdeelVoorProduct.aanvangsdatum.plusMonths(
          leningdeelVoorProduct.looptijd
        );
      }
      break;
    default:
      /* istanbul ignore next */
      assertNever(nieuwVermogen.soortProduct);
  }

  nieuwVermogen.verpanding.bedoeldVoorAflossingSpecificatie.aflosproducten = bepaalDefaultAflosproducten(options);
  nieuwVermogen.inleggegevens.duur = { ...nieuwVermogen.product.looptijd };

  return nieuwVermogen;
};

const Vermogen = (props: VermogenProps & FormikProps<VermogensType> & WithSaveData<VermogensType>): ReactElement => {
  const { isSubmitting, situatie, values } = props;
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { producten } = values;
  const selectedProduct = producten && producten[selected];
  const featureNewDashboard = useFeature("FeatureNewDashboard");
  const vermogensKolommen: AdviesBoxColumn[] = React.useMemo(() => bepaalVermogensKolommen(situatie), [situatie]);

  const {
    data: ingesteldeVermogensbeheerdersData,
    loading: vermogensbeheerdersLoading,
    error: vermogensbeheerdersError
  } = useInstellingenBeheerPartijenData("Vermogensbeheerders");

  if ((vermogensbeheerdersLoading || vermogensbeheerdersError) && !ingesteldeVermogensbeheerdersData) {
    throw Error("Instellingen kunnen niet opgehaald worden");
  }

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {isSubmitting && <PageLoading />}

            <CardWrapper className="px-3">
              <div className="text-container">
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
                <DataGrid
                  masterDetail
                  rowCaption="Vermogen"
                  columns={vermogensKolommen}
                  rowSelected={selectedState}
                  name="producten"
                  validationSchema={vermogenSchema}
                  popup={
                    <ProductSelectieAjax
                      situatie={situatie}
                      productSoort="vermogen"
                      productFilter={
                        /* istanbul ignore next */ (product: AlleMogelijkeProductBasisTypes) =>
                          vermogenFilter(product, values.leningdelen) &&
                          vermogensbeheerdersFilter(product.maatschappijCode || "", ingesteldeVermogensbeheerdersData)
                      }
                      productFactory={productSelectie => newVermogenObjectFactory(productSelectie, values, situatie)}
                    />
                  }
                  additionalButton={
                    situatie === "huidig" &&
                      selectedProduct &&
                      selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null ? (
                        <ProductWijzigingenMeenemenButton
                          name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
                        />
                      ) : (
                        undefined
                      )
                  }
                />
              </Card>
            </CardWrapper>

            {selectedProduct && <VermogenDetails situatie={situatie} selected={selected} />}

            <DevDebug />
          </div>
          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={props} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};

Vermogen.displayName = "Vermogen";

export default withAdviesboxFormik<VermogenProps & WithSaveData<VermogensType>, VermogensType>({
  // Transform outer props into form values
  mapPropsToValues: (props: VermogenProps): VermogensType => ({
    producten: props.producten,
    aanvrager1: props.aanvrager1,
    aanvrager2: props.aanvrager2,
    leningdelen: props.leningdelen,
    kredieten: props.kredieten,
    geldverstrekkerNaam: props.geldverstrekkerNaam,
    ingangsdatumVoorstel: props.ingangsdatumVoorstel,
    platformApiFouten: props.platformApiFouten
  }),
  validationSchema: vermogensSchema
})(withAITracking(insightsReactPlugin, Vermogen));
