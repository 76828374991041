import React, { ReactElement, useState } from "react";
import { FormikProps, Form, useFormikContext } from "formik";
import { LocalDate } from "@js-joda/core";

import { PageLoading, Card, CardWrapper, AdviesBoxColumn, DataGrid, FormFirstFocus, PlatformFoutenSamenvatting, insightsReactPlugin } from "adviesbox-shared";
import { SituatieSoort, ProductSelectieType } from "../producten-overzicht/infra/producten-overzicht-types";
import ProductSelectieAjax from "../producten-overzicht/product-selectie/product-selectie-ajax";

import { bepaalUitkerendeLijfrenteKolommen } from "./uitkerende-lijfrente-kolommen";
import UitkerendeLijfrenteDetails from "./uitkerende-lijfrente-details/uitkerende-lijfrente-details";
import {
  BelastingBoxOptions,
  SoortLijfrenteProductOptions,
  SoortLijfrenteUitkeringOptions
} from "../.generated/forms/formstypes";

import { DevDebug } from "../shared/components/dev-debug/dev-debug";
import { WithSaveData } from "../shared/utils/save-data";
import { SaveButton } from "../shared/components/save-button/save-button";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { UitkerendeLijfrenteType, UitkerendeLijfrentesType } from "./infra/uitkerende-lijfrente-types";
import { uitkerendeLijfrenteSchema, uitkerendeLijfrentesSchema } from "./infra/uitkerende-lijfrente-schema";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { Dashboard } from "../dashboard/dashboard";
import { useFeature } from "adviesbox-shared";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

export const converteerProductvorm = (mogelijkeProductvorm: any): SoortLijfrenteProductOptions | null => {
  const inStringVorm = mogelijkeProductvorm as string;
  switch (inStringVorm?.toLocaleLowerCase()) {
    case SoortLijfrenteProductOptions.BancaireLijfrente.toString().toLocaleLowerCase():
      return SoortLijfrenteProductOptions.BancaireLijfrente;
    case SoortLijfrenteProductOptions.LijfrenteVerzekering.toString().toLocaleLowerCase():
      return SoortLijfrenteProductOptions.LijfrenteVerzekering;
  }
  return null;
}

export const newUitkerendeLijfrenteObjectFactory = (
  productSelectie: ProductSelectieType,
  ingangsdatumVoorstel: LocalDate | null,
  situatie: SituatieSoort
): UitkerendeLijfrenteType => {
  const nieuweLijfrente = uitkerendeLijfrenteSchema.default();
  nieuweLijfrente.product.wijzigingenInDoorlopendProductOvernemen = null;
  nieuweLijfrente.product.ingangsdatum =
    ingangsdatumVoorstel ??
    LocalDate.now()
      .plusMonths(1)
      .withDayOfMonth(1);
  nieuweLijfrente.indicatieveUitkerendeFase.belastingBox = BelastingBoxOptions.Box1;
  nieuweLijfrente.indicatieveUitkerendeFase.lijfrenteUitkering.berekenen = true;

  const productvorm = converteerProductvorm(productSelectie.codes.productVorm);

  if (productvorm === SoortLijfrenteProductOptions.BancaireLijfrente) {
    nieuweLijfrente.soortProduct = SoortLijfrenteProductOptions.BancaireLijfrente;
  } else if (productvorm === SoortLijfrenteProductOptions.LijfrenteVerzekering) {
    nieuweLijfrente.soortProduct = SoortLijfrenteProductOptions.LijfrenteVerzekering;
    nieuweLijfrente.indicatieveUitkerendeFase.soortLijfrenteUitkering = SoortLijfrenteUitkeringOptions.Levenslang;
  } else {
    throw new Error("Unexpected productVorm: " + productSelectie.codes.productVorm);
  }

  return nieuweLijfrente;
};

type UitkerendeLijfrenteProps = { situatie: SituatieSoort };

const UitkerendeLijfrente = (
  props: FormikProps<UitkerendeLijfrentesType> & WithSaveData<UitkerendeLijfrentesType> & UitkerendeLijfrenteProps
): ReactElement => {
  const formik = useFormikContext<UitkerendeLijfrentesType>();
  const {
    situatie,
    isSubmitting,
    values: { producten, ingangsdatumVoorstel }
  } = props;
  const selectedState = useState(0);
  const [selected] = selectedState;
  const selectedProduct = producten && producten[selected];
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  const uitkerendeLijfrenteKolommen: AdviesBoxColumn[] = React.useMemo(
    () => bepaalUitkerendeLijfrenteKolommen(situatie),
    [situatie]
  );

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {isSubmitting && <PageLoading />}

            <CardWrapper className="px-3">
              <div className="text-container">
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
                <DataGrid
                  masterDetail
                  rowCaption="Uitkerende lijfrente"
                  columns={uitkerendeLijfrenteKolommen}
                  rowSelected={selectedState}
                  name="producten"
                  validationSchema={uitkerendeLijfrenteSchema}
                  popup={
                    <ProductSelectieAjax
                      situatie={situatie}
                      productSoort="uitkerende-lijfrente"
                      productFactory={productSelectie =>
                        newUitkerendeLijfrenteObjectFactory(productSelectie, ingangsdatumVoorstel, situatie)
                      }
                    />
                  }
                  additionalButton={
                    situatie === "huidig" &&
                    selectedProduct &&
                    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null && (
                      <ProductWijzigingenMeenemenButton
                        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
                      />
                    )
                  }
                />
              </Card>
            </CardWrapper>

            {selectedProduct && <UitkerendeLijfrenteDetails situatie={situatie} selected={selected} />}

            <DevDebug />
          </div>
          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={formik} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};

UitkerendeLijfrente.displayName = "UitkerendeLijfrente";

export default withAdviesboxFormik<
  UitkerendeLijfrentesType & WithSaveData<UitkerendeLijfrentesType> & UitkerendeLijfrenteProps,
  UitkerendeLijfrentesType
>({
  // Transform outer props into form values
  mapPropsToValues: (props: UitkerendeLijfrentesType): UitkerendeLijfrentesType => ({
    producten: props.producten,
    aanvrager1: props.aanvrager1,
    aanvrager2: props.aanvrager2,
    ingangsdatumVoorstel: props.ingangsdatumVoorstel
  }),

  validationSchema: uitkerendeLijfrentesSchema
})(withAITracking(insightsReactPlugin, UitkerendeLijfrente));
