/* istanbul ignore file */
/* eslint-disable */
import { MotiveringCompleetOutput } from "../../../../.generated/rapportage/rapportagetypes";
import { InkomensanalyseProps } from "../werkloosheid-drawer";
import { OverlijdenProps } from "../overlijden-drawer";
import { ArbeidsongeschiktheidProps } from "../arbeidsongeschiktheid-drawer";
import { PensioenDataProps } from "../pensioen-drawer";
import { optellen } from "adviesbox-shared";
import { AanvragerKeuze } from "../../../../shared/types";

// todo: nog niet op 'Beiden' aanvragers voorbereid.
function getInkomstenPeriodes(motiveringDl: MotiveringCompleetOutput, partner: boolean) {
  const resultaat = motiveringDl.inkomensanalyseResultaat;
  const aanvragerResultaten = resultaat?.aanvragerResultaten;
  const partnerResultaten = resultaat?.partnerResultaten;

  return {
    aanvragerInkomstenPeriodeEerst: aanvragerResultaten?.[0],
    aanvragerInkomstenPeriodeTweede: aanvragerResultaten?.[1],
    aanvragerInkomstenPeriodeDerde: aanvragerResultaten?.[2],
    partnerInkomstenPeriodeEerste: partnerResultaten?.[0],
    partnerInkomstenPeriodeTweede: partnerResultaten?.[1],
    partnerInkomstenPeriodeDerde: partnerResultaten?.[2]
  };
}

export function mapMotiveringWWToInkomensanalyseProps(
  motiveringDl: MotiveringCompleetOutput,
  aanvragerkeuze: AanvragerKeuze
): InkomensanalyseProps {
  const inkomensAnalyseResultaatDl = motiveringDl.inkomensanalyseResultaat;
  const {
    aanvragerInkomstenPeriodeEerst,
    aanvragerInkomstenPeriodeTweede,
    aanvragerInkomstenPeriodeDerde,
    partnerInkomstenPeriodeEerste,
    partnerInkomstenPeriodeTweede,
    partnerInkomstenPeriodeDerde
  } = getInkomstenPeriodes(motiveringDl, aanvragerkeuze === AanvragerKeuze.Aanvrager2);

  const normGegevens = motiveringDl.normGegevens;

  if (!inkomensAnalyseResultaatDl || !aanvragerInkomstenPeriodeEerst || !normGegevens) {
    return {} as InkomensanalyseProps;
  }

  return {
    titel: "Inkomensanalyse bij werkloosheid",
    totaalHuidigBrutoJaarinkomenBedrag: inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag,
    conclusie: aanvragerInkomstenPeriodeEerst.conclusie || "",
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    motiveringInkomensanalyse: motiveringDl.motiveringInkomensanalyse || null,
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    nbiWeergeven: false,
    inkomensData: {
      naamPersoon:
        aanvragerkeuze === AanvragerKeuze.Aanvrager2
          ? motiveringDl.klantNaamPartner || "Partner"
          : motiveringDl.klantNaamAanvrager || "Aanvrager",
      eersteUitkering: aanvragerInkomstenPeriodeEerst.wwUitkeringBedrag,
      tweedeUitkering: aanvragerInkomstenPeriodeTweede?.wwUitkeringBedrag || 0,
      restinkomen: aanvragerInkomstenPeriodeEerst?.restInkomenBedrag,
      aantalmaandenWwUitkering: aanvragerInkomstenPeriodeEerst?.aantalMaandenWwUitkering || 0,
      totaalEerste: inkomensAnalyseResultaatDl.totaalBrutoInkomenBedrag,
      totaalTweede: inkomensAnalyseResultaatDl.totaalBrutoInkomenBedrag,
      totaalNa: inkomensAnalyseResultaatDl.totaalBrutoInkomenBedrag,
      inkomensverliesEerste: Math.max(
        optellen([
          inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
          ((aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0) +
            (aanvragerInkomstenPeriodeEerst?.wwUitkeringBedrag || 0) +
            (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0) +
            (inkomensAnalyseResultaatDl?.aanvragerResultaten?.[0]?.woonlastenverzekeringBruteringBedrag || 0) +
            (inkomensAnalyseResultaatDl?.aanvragerResultaten?.[0]?.woonlastenverzekeringBedrag || 0)
          ) /
          12
        ]) || 0,
        0
      ),
      inkomensverliesTweede: Math.max(
        optellen([
          inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
          ((aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0) +
            (aanvragerInkomstenPeriodeTweede?.wwUitkeringBedrag || 0) +
            (partnerInkomstenPeriodeTweede?.restInkomenBedrag || 0) +
            (inkomensAnalyseResultaatDl?.aanvragerResultaten?.[1]?.woonlastenverzekeringBruteringBedrag || 0) +
            (inkomensAnalyseResultaatDl?.aanvragerResultaten?.[1]?.woonlastenverzekeringBedrag || 0)
          ) /
          12
        ]) || 0,
        0
      ),
      inkomensverliesDerde: Math.max(
        optellen([

          inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
          ((aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0) +
            (aanvragerInkomstenPeriodeDerde?.wwUitkeringBedrag || 0) +
            (partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0) +
            (inkomensAnalyseResultaatDl?.aanvragerResultaten?.[2]?.woonlastenverzekeringBruteringBedrag || 0) +
            (inkomensAnalyseResultaatDl?.aanvragerResultaten?.[2]?.woonlastenverzekeringBedrag || 0)
          ) /
          12
        ]) || 0,
        0
      ),
      totaalInkomen: optellen([
        aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0,
        aanvragerInkomstenPeriodeEerst?.wwUitkeringBedrag || 0,
        partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0
      ]),
      totaalInkomenTweede: optellen([
        aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
        aanvragerInkomstenPeriodeTweede?.wwUitkeringBedrag || 0,
        partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0
      ]),
      totaalInkomenDerde: optellen([
        aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
        partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0
      ]),
      woonlastenEerstePeriode: (aanvragerInkomstenPeriodeEerst.woonlastenverzekeringBedrag || 0) + (aanvragerInkomstenPeriodeEerst.woonlastenverzekeringBruteringBedrag || 0),
      woonlastenTweedePeriode: (aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBedrag || 0) + (aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBruteringBedrag || 0),
      woonlastenDerdePeriode: (aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBedrag || 0) + (aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBruteringBedrag || 0),
      inkomenPartnerEerstePeriode: partnerInkomstenPeriodeEerste?.restInkomenBedrag || null,
      inkomenPartnerTweedePeriode: partnerInkomstenPeriodeTweede?.restInkomenBedrag || null,
      inkomenPartnerDerdePeriode: partnerInkomstenPeriodeDerde?.restInkomenBedrag || null,
    },
    inkomensDataPartner: {
      persoon:
        aanvragerkeuze === AanvragerKeuze.Aanvrager2
          ? motiveringDl.klantNaamAanvrager || "Aanvrager"
          : motiveringDl.klantNaamPartner || "Partner",
      eersteUitkering: partnerInkomstenPeriodeEerste?.wwUitkeringBedrag || null,
      tweedeUitkering: partnerInkomstenPeriodeEerste?.wwUitkeringBedrag || null,
      restinkomen: partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
      aantalmaandenWwUitkering: partnerInkomstenPeriodeEerste?.aantalMaandenWwUitkering || 0,
      totaalEerste: inkomensAnalyseResultaatDl.totaalBrutoInkomenBedrag,
      totaalTweede: inkomensAnalyseResultaatDl.totaalBrutoInkomenBedrag,
      totaalNa: inkomensAnalyseResultaatDl.totaalBrutoInkomenBedrag,
      tekortEerste: normGegevens?.tekortGewenstBrutoInkomenBedrag,
      tekortTweede: 0, //todo,
      tekortNa: normGegevens?.tekortGewenstBrutoInkomenBedrag,
      totaalInkomen: null,
      totaalInkomenTweede: optellen([
        partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
        partnerInkomstenPeriodeEerste?.wwUitkeringBedrag || 0,
        aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0
      ]),
      totaalInkomenDerde: optellen([
        partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
        aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0
      ])
    },
    jaarlijkseAnalyse: {
      gemiddeldeInkomen: inkomensAnalyseResultaatDl.totaalBrutoInkomenGemiddeldBedrag,
      gewenstInkomen: normGegevens?.gewenstBrutoInkomenBedrag,
      gewenstInkomenPercentage:
        ((normGegevens.gewenstBrutoInkomenBedrag || 1) /
          (inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag || 1)) *
        100,
      maandelijksTekort: normGegevens.gewenstBrutoInkomenBedrag
        ? Math.max(
          inkomensAnalyseResultaatDl.totaalBrutoInkomenGemiddeldBedrag - normGegevens.gewenstBrutoInkomenBedrag,
          0
        ) / 12
        : 0
    },
    financieleGegevens: {
      toegestaneLast: normGegevens?.toegestaneLastBedrag,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag,
      tekortWerkelijkeBrutolast: (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0),
      maximaleHypotheek: normGegevens?.maximaleHypotheekBedrag,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      benodigdInkomenHypotheek: normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      tekortOpInkomen: normGegevens.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || 0,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
}

export function mapMotiveringAOToInkomensanalyseProps(
  motiveringDl: MotiveringCompleetOutput,
  aanvragerKeuze: AanvragerKeuze
): ArbeidsongeschiktheidProps {
  const inkomensAnalyseResultaatDl = motiveringDl.inkomensanalyseResultaat;

  const {
    aanvragerInkomstenPeriodeEerst,
    aanvragerInkomstenPeriodeTweede,
    aanvragerInkomstenPeriodeDerde,
    partnerInkomstenPeriodeEerste,
    partnerInkomstenPeriodeTweede,
    partnerInkomstenPeriodeDerde
  } = getInkomstenPeriodes(motiveringDl, aanvragerKeuze === AanvragerKeuze.Aanvrager2);

  const scenarioGegevens = motiveringDl.inkomensanalyseResultaat?.scenarioGegevens;
  const normGegevens = motiveringDl.normGegevens;

  if (!inkomensAnalyseResultaatDl || !aanvragerInkomstenPeriodeEerst || !normGegevens) {
    return {} as ArbeidsongeschiktheidProps;
  }

  return {
    nbiWeergeven: false,
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    titel: "Inkomensanalyse bij arbeidsongeschiktheid",
    totaalHuidigBrutoJaarinkomenBedrag: inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag,
    motiveringInkomensanalyse: motiveringDl.motiveringInkomensanalyse,
    conclusieEerste: aanvragerInkomstenPeriodeEerst.conclusie || "",
    conclusieTweede: aanvragerInkomstenPeriodeTweede?.conclusie || "",
    conclusieDerde: aanvragerInkomstenPeriodeDerde?.conclusie || "",
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    inkomensData: {
      persoon: motiveringDl.klantNaamAanvrager || "Aanvrager",
      inkomenAanvragerEerstePeriode: aanvragerInkomstenPeriodeEerst.restInkomenBedrag,
      inkomenAanvragerTweedePeriode: aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
      inkomenAanvragerDerdePeriode: aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
      inkomenPartnerEerstePeriode: partnerInkomstenPeriodeEerste?.restInkomenBedrag || null,
      inkomenPartnerTweedePeriode: partnerInkomstenPeriodeTweede?.restInkomenBedrag || null,
      inkomenPartnerDerdePeriode: partnerInkomstenPeriodeDerde?.restInkomenBedrag || null,
      invaliditeitspensioenEerstePeriode: (aanvragerInkomstenPeriodeEerst.invaliditeitspensioenBedrag || 0) + (partnerInkomstenPeriodeEerste?.invaliditeitspensioenBedrag || 0),
      invaliditeitspensioenTweedePeriode: (aanvragerInkomstenPeriodeTweede?.invaliditeitspensioenBedrag || 0) + (partnerInkomstenPeriodeTweede?.invaliditeitspensioenBedrag || 0),
      invaliditeitspensioenDerdePeriode: (aanvragerInkomstenPeriodeDerde?.invaliditeitspensioenBedrag || 0) + (partnerInkomstenPeriodeDerde?.invaliditeitspensioenBedrag || 0),
      wgaUitkeringAanvragerEerstePeriode: aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag || 0 + (partnerInkomstenPeriodeEerste?.wgaUitkeringBedrag || 0),
      wgaUitkeringAanvragerTweedePeriode: aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0 + (partnerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0),
      wgaUitkeringAanvragerDerdePeriode: aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0 + (partnerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0),
      totaalInkomenEerstePeriode: optellen([
        aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
        aanvragerInkomstenPeriodeEerst.restInkomenBedrag || 0,
        partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
        aanvragerInkomstenPeriodeEerst?.ivaUitkeringBedrag || 0,
        aanvragerInkomstenPeriodeEerst?.invaliditeitspensioenBedrag || 0
      ]),
      totaalInkomenTweedePeriode: optellen([
        aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
        aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
        partnerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
        aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0,
        aanvragerInkomstenPeriodeTweede?.invaliditeitspensioenBedrag || 0
      ]),
      totaalInkomenDerdePeriode: optellen([
        aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
        aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
        partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
        aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0,
        aanvragerInkomstenPeriodeDerde?.invaliditeitspensioenBedrag || 0
      ]),
      inkomensverliesEerste: Math.max(
        inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
          (aanvragerInkomstenPeriodeEerst.woonlastenverzekeringBruteringBedrag || 0) +
          (aanvragerInkomstenPeriodeEerst?.woonlastenverzekeringBedrag || 0),
          aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeEerst?.ivaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeEerst?.invaliditeitspensioenBedrag || 0
        ]) || 0) /
        12,
        0
      ),
      inkomensverliesTweede: Math.max(
        inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
          (aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBruteringBedrag || 0) +
          (aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBedrag || 0),
          aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.invaliditeitspensioenBedrag || 0
        ]) || 0) /
        12,
        0
      ),
      inkomensverliesDerde: Math.max(
        inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
          (aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBruteringBedrag || 0) +
          (aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBedrag || 0),
          aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeDerde?.invaliditeitspensioenBedrag || 0
        ]) || 0) /
        12,
        0
      ),
      percentageAoEerste: scenarioGegevens?.percentageArbeidsongeschiktheid || 0,
      percentageAoTweede: scenarioGegevens?.percentageArbeidsongeschiktheidExtra1 || 0,
      percentageAoDerde: scenarioGegevens?.percentageArbeidsongeschiktheidExtra2 || 0,
      ivaUitkeringAanvragerEerstePeriode: (aanvragerInkomstenPeriodeEerst?.ivaUitkeringBedrag || 0) + (partnerInkomstenPeriodeEerste?.ivaUitkeringBedrag || 0),
      ivaUitkeringAanvragerTweedePeriode: (aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0) + (partnerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0),
      ivaUitkeringAanvragerDerdePeriode: (aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0) + (partnerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0),
      woonlastenEerstePeriode:
        (aanvragerInkomstenPeriodeEerst?.woonlastenverzekeringBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.woonlastenverzekeringBruteringBedrag || 0),
      woonlastenTweedePeriode:
        (aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBedrag || 0) +
        (aanvragerInkomstenPeriodeTweede?.woonlastenverzekeringBruteringBedrag || 0),
      woonlastenDerdePeriode:
        (aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBedrag || 0) +
        (aanvragerInkomstenPeriodeDerde?.woonlastenverzekeringBruteringBedrag || 0),
      rvcEerste:
        ((100 - (scenarioGegevens?.percentageArbeidsongeschiktheid || 0)) *
          (scenarioGegevens?.verdiencapaciteitPercentage || 0)) /
        100,
      rvcTweede:
        ((100 - (scenarioGegevens?.percentageArbeidsongeschiktheidExtra1 || 0)) *
          (scenarioGegevens?.verdiencapaciteitPercentageExtra1 || 0)) /
        100,
      rvcDerde:
        ((100 - (scenarioGegevens?.percentageArbeidsongeschiktheidExtra2 || 0)) *
          (scenarioGegevens?.verdiencapaciteitPercentageExtra2 || 0)) /
        100,
      brutoInkomenPartnerEerstePeriode: partnerInkomstenPeriodeEerste?.nettoInkomstenBedrag || 0,
      brutoInkomenPartnerTweedePeriode: motiveringDl.inkomensanalyseResultaat?.totaalBrutoInkomenBedrag || 0,
      brutoInkomenPartnerDerdePeriode: motiveringDl.inkomensanalyseResultaat?.totaalBrutoInkomenBedrag || 0,

    },
    jaarlijkseAnalyse: {
      gewenstInkomen: normGegevens.gewenstBrutoInkomenBedrag,
      maandelijksTekortEerste: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
          aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeEerst?.ivaUitkeringBedrag || 0
        ]) || 0) /
        12 -
        (normGegevens.gewenstBrutoInkomenBedrag || 0) / 12,
        0 // min0
      ),
      maandelijksTekortTweede: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0
        ]) || 0) /
        12 -
        (normGegevens.gewenstBrutoInkomenBedrag || 0) / 12,
        0 // min0
      ),
      maandelijksTekortDerde: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0
        ]) || 0) /
        12 -
        (normGegevens.gewenstBrutoInkomenBedrag || 0) / 12,
        0
      ) // min0
    },
    financieleGegevens: {
      toegestaneLast: normGegevens?.toegestaneLastBedrag || 0,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag || 0,
      tekortOpInkomen: Math.abs(
        normGegevens.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0
      ),
      tekortOpInkomenTweede: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0
        ]) || 0) /
        12 -
        (normGegevens.benodigdInkomenBedrag || 0) / 12,
        0 //min0
      ),
      tekortOpInkomenDerde: Math.min(
        (optellen([
          aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
          aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
          partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
          aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0
        ]) || 0) /
        12 -
        (normGegevens.benodigdInkomenBedrag || 0) / 12,
        0
      ),
      tekortWerkelijk: normGegevens?.tekortBenodigInkomenBedrag || 0,
      tekortWerkelijkeBrutolast: Math.min(
        (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0),
        0
      ),
      maximaleHypotheekEerste: normGegevens?.maximaleHypotheekBedrag,
      maximaleHypotheekTweede: normGegevens?.maximaleHypotheekBedrag,
      maximaleHypotheekDerde: normGegevens?.maximaleHypotheekBedrag,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      benodigdInkomenHypotheek: normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      maandelijksTekortHypotheekEerste:
        Math.min(
          (optellen([
            aanvragerInkomstenPeriodeEerst.wgaUitkeringBedrag,
            aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0,
            partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
            aanvragerInkomstenPeriodeEerst?.ivaUitkeringBedrag || 0
          ]) || 0) /
          12 -
          (normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0)
        ) *
        (1 - (aanvragerInkomstenPeriodeEerst?.bruteringPercentage || 0) / 100), //totaal-inkomen - minimaalBenodigHypotheek,
      maandelijksTekortHypotheekTweede:
        Math.min(
          (optellen([
            aanvragerInkomstenPeriodeTweede?.wgaUitkeringBedrag || 0,
            aanvragerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
            partnerInkomstenPeriodeTweede?.restInkomenBedrag || 0,
            aanvragerInkomstenPeriodeTweede?.ivaUitkeringBedrag || 0
          ]) || 0) /
          12 -
          (normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0)
        ) *
        (1 - (aanvragerInkomstenPeriodeTweede?.bruteringPercentage || 0) / 100),
      maandelijksTekortHypotheekDerde:
        Math.min(
          (optellen([
            aanvragerInkomstenPeriodeDerde?.wgaUitkeringBedrag || 0,
            aanvragerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
            partnerInkomstenPeriodeDerde?.restInkomenBedrag || 0,
            aanvragerInkomstenPeriodeDerde?.ivaUitkeringBedrag || 0
          ]) || 0) /
          12 -
          (normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0)
        ) *
        (1 - (aanvragerInkomstenPeriodeDerde?.bruteringPercentage || 0) / 100)
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || null,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
}

export function mapMotiveringOverlijdenToInkomensanalyseProps(motiveringDl: MotiveringCompleetOutput): OverlijdenProps {
  const inkomensAnalyseResultaatDl = motiveringDl.inkomensanalyseResultaat;
  const {
    aanvragerInkomstenPeriodeEerst,
    aanvragerInkomstenPeriodeTweede,
    aanvragerInkomstenPeriodeDerde,
    partnerInkomstenPeriodeEerste,
    partnerInkomstenPeriodeTweede,
    partnerInkomstenPeriodeDerde
  } = getInkomstenPeriodes(motiveringDl, false);
  const normGegevens = motiveringDl.normGegevens;

  if (!inkomensAnalyseResultaatDl || !partnerInkomstenPeriodeEerste || !normGegevens) {
    return {} as OverlijdenProps;
  }

  return {
    nbiWeergeven: false,
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    motiveringInkomensanalyse: motiveringDl.motiveringInkomensanalyse,
    titel: "Inkomensanalyse bij overlijden",
    totaalHuidigBrutoJaarinkomenBedrag: inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag,
    conclusie: partnerInkomstenPeriodeEerste.conclusie || "",
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    controleInkomenWeergeven: false,
    inkomensData: {
      anwUitkeringBedrag: partnerInkomstenPeriodeEerste.anwUitkeringBedrag
        ? partnerInkomstenPeriodeEerste.anwUitkeringBedrag / 12
        : 0,
      anwHiaatVerzekeringBedrag: partnerInkomstenPeriodeEerste.anwHiaatVerzekeringBedrag
        ? partnerInkomstenPeriodeEerste.anwHiaatVerzekeringBedrag / 12
        : 0,
      anwHiaatVerzekeringBruteringBedrag: partnerInkomstenPeriodeEerste.anwHiaatVerzekeringBruteringBedrag
        ? partnerInkomstenPeriodeEerste.anwHiaatVerzekeringBruteringBedrag / 12
        : 0,
      nabestaandenpensioen: partnerInkomstenPeriodeEerste.nabestaandenpensioenBedrag,
      wezenpensioen: partnerInkomstenPeriodeEerste.wezenPensioenuitkeringBedrag,
      inkomensaanvullingBedrag: partnerInkomstenPeriodeEerste.aanvullingInkomenBedrag
      ? partnerInkomstenPeriodeEerste.aanvullingInkomenBedrag / 12
      : 0,
      inkomensaanvullingBruteringBedrag: partnerInkomstenPeriodeEerste.aanvullingInkomenBruteringBedrag
        ? partnerInkomstenPeriodeEerste.aanvullingInkomenBruteringBedrag / 12
        : 0,
      inkomenPartner: partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0,
      totaalInkomen:
        (optellen([
          partnerInkomstenPeriodeEerste?.anwUitkeringBedrag || 0,
          partnerInkomstenPeriodeEerste?.nabestaandenpensioenBedrag || 0,
          partnerInkomstenPeriodeEerste?.aanvullingInkomenBedrag || 0,
          partnerInkomstenPeriodeEerste?.aanvullingInkomenBruteringBedrag || 0,
          partnerInkomstenPeriodeEerste?.wezenPensioenuitkeringBedrag || 0,
          partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0
        ]) || 0) / 12,
      inkomensverlies: Math.max(
        inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag / 12 -
        (optellen([
          aanvragerInkomstenPeriodeEerst?.anwUitkeringBedrag || 0,
          (aanvragerInkomstenPeriodeEerst?.nabestaandenpensioenBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.aanvullingInkomenBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.aanvullingInkomenBruteringBedrag || 0) +
          (aanvragerInkomstenPeriodeEerst?.wezenPensioenuitkeringBedrag || 0),
          partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0
        ]) || 0) /
        12,
        0
      )
    },
    jaarlijkseAnalyse: {
      gewenstInkomen: normGegevens?.gewenstBrutoInkomenBedrag,
      gewenstInkomenPercentage:
        ((normGegevens.gewenstBrutoInkomenBedrag || 1) /
          (inkomensAnalyseResultaatDl.totaalHuidigBrutoJaarinkomenBedrag || 1)) *
        100,
      maandelijksTekort: Math.max(
        (normGegevens?.gewenstBrutoInkomenBedrag ? (normGegevens?.gewenstBrutoInkomenBedrag || 0) / 12 : 0) -
        (optellen([
          partnerInkomstenPeriodeEerste?.anwUitkeringBedrag || 0,
          partnerInkomstenPeriodeEerste?.nabestaandenpensioenBedrag || 0,
          partnerInkomstenPeriodeEerste?.aanvullingInkomenBedrag || 0,
          partnerInkomstenPeriodeEerste?.aanvullingInkomenBruteringBedrag || 0,
          partnerInkomstenPeriodeEerste?.wezenPensioenuitkeringBedrag || 0,
          partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0
        ]) || 0) /
        12,
        0
      )
    },
    financieleGegevens: {
      benodigdInkomenHypotheek: normGegevens.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      tekortOpInkomen: normGegevens.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0,
      maximaleHypotheek: normGegevens?.maximaleHypotheekBedrag || null,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag || null,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      toegestaneLast: normGegevens?.toegestaneLastBedrag || null,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag || null,
      tekortWerkelijkeBrutolast: (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0)
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || null,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
}

export const mapMotiveringPensioenToInkomensanalyseProps = (
  motiveringDl: MotiveringCompleetOutput
): PensioenDataProps => {
  const {
    aanvragerInkomstenPeriodeEerst,
    aanvragerInkomstenPeriodeTweede,
    aanvragerInkomstenPeriodeDerde,
    partnerInkomstenPeriodeEerste,
    partnerInkomstenPeriodeTweede,
    partnerInkomstenPeriodeDerde
  } = getInkomstenPeriodes(motiveringDl, false);
  const normGegevens = motiveringDl?.normGegevens;
  const resultaat = motiveringDl.inkomensanalyseResultaat;

  if (!resultaat) throw Error("No inkomensanalyseResultaat found");

  return {
    nbiWeergeven: false,
    inkomensanalyseResultaten: motiveringDl.motiveringInkomensanalyse?.resultaten || [],
    titel: "",
    totaalHuidigBrutoJaarinkomenBedrag: resultaat.totaalHuidigBrutoJaarinkomenBedrag,
    motiveringInkomensanalyse: motiveringDl.motiveringInkomensanalyse,
    conclusie: aanvragerInkomstenPeriodeEerst?.conclusie || "",
    nbi: motiveringDl.nettoBesteedbaarInkomen || [],
    inkomensData: {
      restinkomenAanvrager:
        (aanvragerInkomstenPeriodeEerst?.aowUitkeringBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.werkgeverspensioenBedrag || 0),
      restinkomenPartner: partnerInkomstenPeriodeEerste?.restInkomenBedrag || null,
      totaalInkomen:
        (aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.aowUitkeringBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.aowUitkeringBedrag || 0) +
        (aanvragerInkomstenPeriodeEerst?.werkgeverspensioenBedrag || 0) +
        (partnerInkomstenPeriodeEerste?.werkgeverspensioenBedrag || 0),
      inkomensverlies: resultaat?.totaalHuidigBrutoJaarinkomenBedrag / 12 - resultaat?.totaalBrutoInkomenBedrag / 12,
      werkgeversPensioenAanvrager: aanvragerInkomstenPeriodeEerst?.werkgeverspensioenBedrag || 0,
      werkgeversPensioenPartner: partnerInkomstenPeriodeEerste?.werkgeverspensioenBedrag || 0,
      aowInkomenAanvrager: aanvragerInkomstenPeriodeEerst?.aowUitkeringBedrag || 0,
      aowInkomenPartner: partnerInkomstenPeriodeEerste?.aowUitkeringBedrag || 0
    },
    financieleGegevens: {
      huidigInkomen70Procent: (resultaat?.totaalHuidigBrutoJaarinkomenBedrag / 12) * 0.7,
      toegestaneLast: normGegevens?.toegestaneLastBedrag || null,
      werkelijkeBrutoLast: normGegevens?.werkelijkeLastBedrag || null,
      tekortWerkelijkeBrutolast: (normGegevens?.toegestaneLastBedrag || 0) - (normGegevens?.werkelijkeLastBedrag || 0),
      tekortWerkelijk: normGegevens?.tekortBenodigInkomenBedrag || null,
      maximaleHypotheek: normGegevens?.maximaleHypotheekBedrag || null,
      hypotheekSaldo: normGegevens?.hypotheeksaldoBedrag || null,
      overschrijding: Math.min(
        (normGegevens?.maximaleHypotheekBedrag || 0) - (normGegevens?.hypotheeksaldoBedrag || 0),
        0
      ),
      benodigdInkomenHypotheek: normGegevens?.benodigdInkomenBedrag ? normGegevens.benodigdInkomenBedrag / 12 : 0,
      tekortOpInkomen: normGegevens?.tekortBenodigInkomenBedrag ? normGegevens.tekortBenodigInkomenBedrag / 12 : 0
    },
    jaarlijkseAnalyse: {
      gewenstInkomen: normGegevens?.gewenstBrutoInkomenBedrag || 0,
      gewenstInkomenPercentage:
        ((normGegevens?.gewenstBrutoInkomenBedrag || 1) / (resultaat.totaalHuidigBrutoJaarinkomenBedrag || 1)) * 100,
      maandelijksTekort: Math.min(
        ((aanvragerInkomstenPeriodeEerst?.restInkomenBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.restInkomenBedrag || 0) +
          (aanvragerInkomstenPeriodeEerst?.aowUitkeringBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.aowUitkeringBedrag || 0) +
          (aanvragerInkomstenPeriodeEerst?.werkgeverspensioenBedrag || 0) +
          (partnerInkomstenPeriodeEerste?.werkgeverspensioenBedrag || 0) -
          (normGegevens?.gewenstBrutoInkomenBedrag || 0)) /
        12,
        0
      )
    },
    voetnoten: motiveringDl.toelichtingRegels || [],
    benodigdKapitaal: motiveringDl.benodigdKapitaal?.bedrag || 0,
    benodigdKapitaalOmschrijving: motiveringDl.benodigdKapitaal?.omschrijving || ""
  };
};
