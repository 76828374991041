import {
  AdviesBoxColumn,
  beschikbareAovProducten,
  Card,
  CardWrapper,
  DataGrid,
  ErrorPage,
  FormFirstFocus,
  PageLoading,
  PlatformFoutenSamenvatting,
  insightsReactPlugin
} from "adviesbox-shared";
import { Form, FormikProps, useFormikContext } from "formik";
import React, { ReactElement, useState } from "react";
import { ProductBasis } from "../.generated/producten/productentypes";
import { getSituatieFilter } from "../producten-overzicht/infra/product-situatie-filter";
import { AlleMogelijkeProductBasisTypes, SituatieSoort } from "../producten-overzicht/infra/producten-overzicht-types";
import ProductSelectieAjax from "../producten-overzicht/product-selectie/product-selectie-ajax";
import { ProductWijzigingenMeenemenButton } from "../producten-overzicht/product-wijzigingen-meenemen-button/product-wijzigingen-meenemen-button";
import { Debug } from "../shared/components/formik/Debug";
import { SaveButton } from "../shared/components/save-button/save-button";
import { useInstellingenBeheerPartijenData } from "../shared/hooks/use-instellingen-beheer-partijen-data";
import { partijFilter } from "../shared/utils/partij-filter";
import { WithSaveData } from "../shared/utils/save-data";
import { withAdviesboxFormik } from "../shared/utils/with-adviesbox-formik";
import { AovDetails } from "./aov-details/aov-details";
import { createFormValidationContext, newAovObjectFactory } from "./aov-helpers";
import { aovColumns } from "./aov-kolommen";
import { AovsProps, aovsSchema, AovsType } from "./infra/aov-schema";
import { useFeature } from "adviesbox-shared";
import { Dashboard } from "../dashboard/dashboard";
import { withAITracking } from "@microsoft/applicationinsights-react-js";

const AovComponent = (props: FormikProps<AovsType> & AovsProps & { situatie: SituatieSoort }): ReactElement => {
  const formik = useFormikContext<AovsType>();
  const { isSubmitting, situatie, tarieven } = props;
  const selectedState = useState(0);
  const [selected] = selectedState;
  const { producten, ingangsdatumVoorstel } = formik.values;
  const selectedProduct = producten && producten[selected] ? producten[selected] /* istanbul ignore next */ : null;
  const aovKolommen: AdviesBoxColumn[] = React.useMemo(() => aovColumns(situatie), [situatie]);
  const featureNewDashboard = useFeature("FeatureNewDashboard");

  const {
    data: ingesteldeVerzekeraarsData,
    loading: verzekeraarsLoading,
    error: verzekeraarsError
  } = useInstellingenBeheerPartijenData("Verzekeraars");

  if (verzekeraarsError) {
    return <ErrorPage error={verzekeraarsError} data={ingesteldeVerzekeraarsData} />;
  }

  return (
    <FormFirstFocus>
      <Form>
        <div className="d-flex content_wrapper">
          <div className="content">
            {(isSubmitting || verzekeraarsLoading) && <PageLoading />}

            <CardWrapper className="px-3">
              <div className="text-container">
                <div className="save-btn-position">
                  <div className="button-container">
                    <SaveButton context={props} />
                  </div>
                </div>
              </div>
            </CardWrapper>

            <PlatformFoutenSamenvatting />

            <CardWrapper className="px-3 master-detail-card flex-grow-1" maxRowCount={4}>
              <Card className="w-xl-100 w-lg-100 w-md-50 w-50">
                <DataGrid
                  masterDetail
                  rowCaption="AOV"
                  captionToLowerCase={false}
                  columns={aovKolommen}
                  rowSelected={selectedState}
                  additionalButton={
                    situatie === "huidig" &&
                    selectedProduct &&
                    selectedProduct.product.wijzigingenInDoorlopendProductOvernemen !== null ? (
                      <ProductWijzigingenMeenemenButton
                        name={`producten[${selected}].product.wijzigingenInDoorlopendProductOvernemen`}
                      />
                    ) : (
                      undefined
                    )
                  }
                  name="producten"
                  popup={
                    <ProductSelectieAjax
                      situatie={situatie}
                      productSoort="aov"
                      legePartijnaam={true}
                      metProductKenmerken="Aov"
                      productFilter={
                        /* istanbul ignore next */
                        (product: AlleMogelijkeProductBasisTypes) => {
                          return (
                            getSituatieFilter(situatie, product, ingesteldeVerzekeraarsData.verzekeraars?.partijen) &&
                            partijFilter(
                              { maatschappijCode: product.maatschappijCode, code: product.code } as ProductBasis,
                              beschikbareAovProducten
                            )
                          );
                        }
                      }
                      productFactory={
                        /* istanbul ignore next */
                        (productSelectie, kenmerken) =>
                          newAovObjectFactory(productSelectie, situatie, ingangsdatumVoorstel, formik.values, kenmerken)
                      }
                    />
                  }
                />
              </Card>
            </CardWrapper>
            {selectedProduct && <AovDetails selected={selected} situatie={situatie} tarieven={tarieven} />}
            <Debug />
          </div>
          { !featureNewDashboard && (
            <Dashboard situatie={situatie} saveData={props.saveData} formikParent={props} />
          )}
        </div>
      </Form>
    </FormFirstFocus>
  );
};

export const Aov = withAdviesboxFormik<AovsProps & WithSaveData<AovsType>, AovsType>({
  // Transform outer props into form values
  mapPropsToValues: (props: AovsProps): AovsType => ({
    producten: props.producten,
    aanvrager1: props.aanvrager1,
    aanvrager2: props.aanvrager2,
    ingangsdatumVoorstel: props.ingangsdatumVoorstel,
    hypotheekbedrag: props.hypotheekbedrag,
    marktwaardeBedrag: props.marktwaardeBedrag,
    aflosProductNew: props.aflosProductNew
  }),
  validationSchema: aovsSchema,
  createValidationContext: createFormValidationContext
})(withAITracking(insightsReactPlugin, AovComponent));
/* istanbul ignore else */ if (process.env.NODE_ENV !== "production") Aov.displayName = "Aov";
